import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { CommonService } from './commonservice.service';
import { HttpService } from './httpservice.service';

@Injectable({
    providedIn: 'root' 
})
export class AppDataService {
    rawData: any = new Array();
    dashboardData:any={graph1:"",graph2:"",graph3:"",graph4:"",graph5:"",graph6:"",graph7:"",graph8:""};
    analysisData:any={consumption:{filters:"",place:"",premise:""},energycost:{filters:"",place:"",premise:""},comparison:{tabarr:[]},operatinghours:{dateFrom:"",dateTo:""},baseline:{filters:"",place:"",premise:""},forecasting:{filters:"",place:"",premise:"",device:"",uuid:"",graph:"",forecast:""},timeseries:{filters:"",mainObj:""}};
    tariffData: any;
    selectedTariff: any;
    tariffIndex: number;
    latestCurrency: any;
    donutData: { data: any[]; backgroundColor: string[]; hoverBorderColor: string; hoverBorderWidth: number; borderWidth: number; }[];
    donutLabel: any[];
    horBarData: any[];
    numberOfPhase: number = 0;
    constructor(private httpService: HttpService, private commonService: CommonService){}

    public async multipleDevicesData(array,single,compareno, raw?){
        console.log("Normal Function called...");
        let maindata = [];
        
        for(let i=0;i<array.length;i++){
            const params = array[i];
            const resp:any = await this.httpService.getApi(params);

            if(!resp.type){
                console.log("params for failed case "+params);
                if (!!raw) {
                    this.rawData.push(0);
                }
                continue;
            }

            if (!!resp && !!resp.data) {
              for (let i=0; i<resp.data.length; i++) {
                for (let j=1; j<resp.data[i].length; j++) {
                  if (resp.data[i][j] == null) {
                    resp.data[i][j] = 0;
                  }
                }
              }
            }

            if (!!raw) {
                this.rawData.push(resp.data);
            }
            let labelLength = resp.type.split(',');
            if(i == 0){
                for(let j=0;j<resp.data.length;j++){
                    let obj:any = {};

                    obj["unix"] = resp.data[j][0];
                    const date = new Date(resp.data[j][0]*1000);
                    let xseries = !!compareno ? ("time"+compareno) : "time";
                    obj[xseries] = new Date(date.getFullYear(),date.getMonth(),date.getDate(),date.getHours(),date.getMinutes(),date.getSeconds());
              
                    if(!!single){
                        obj["value"+compareno] = resp.data[j][1] + ((labelLength.length>1)?resp.data[j][2]:0) +  ((labelLength.length>2)?resp.data[j][3]:0);
                    }else{
                        obj[labelLength[0]] = resp.data[j][1];
                        if(labelLength.length>1)
                          obj[labelLength[1]] = resp.data[j][2];
                        if(labelLength.length>2 )
                          obj[labelLength[2]] = resp.data[j][3];
                    }
              
                    maindata.push(obj);
                }
            }else{
                for(let k=0;k<resp.data.length;k++){
                    let obj:any = {};
                    
                    obj["unix"] = resp.data[k][0];
                    const date = new Date(resp.data[k][0]*1000);
                    let time = new Date(date.getFullYear(),date.getMonth(),date.getDate(),date.getHours(),date.getMinutes(),date.getSeconds());
                   
                    let index = maindata.findIndex(obj =>  moment(obj.unix).isSame(resp.data[k][0]));
                    if(index >= 0){    
                        
                        if(!!single){
                            maindata[index]["value"+compareno] = ((!!maindata[index]["value"+compareno]?maindata[index]["value"+compareno]:0) + resp.data[k][1]) + ((labelLength.length>1)?resp.data[k][2]:0) +  ((labelLength.length>2)?resp.data[k][3]:0);
                        }else{
                            maindata[index][labelLength[0]] = (!!maindata[index][labelLength[0]]?maindata[index][labelLength[0]]:0) + resp.data[k][1];
                            if(labelLength.length>1)
                                maindata[index][labelLength[1]] = (!!maindata[index][labelLength[1]]?maindata[index][labelLength[1]]:0) + resp.data[k][2];
                            if(labelLength.length>2)
                                maindata[index][labelLength[2]] = (!!maindata[index][labelLength[2]]?maindata[index][labelLength[2]]:0) + resp.data[k][3];
                        }
                        
                    }else{
                        let xseries = !!compareno ? ("time"+compareno) : "time";
                        obj[xseries] = new Date(date.getFullYear(),date.getMonth(),date.getDate(),date.getHours(),date.getMinutes(),date.getSeconds());
              
                        if(!!single){
                            obj["value"+compareno] = resp.data[k][1] + ((labelLength.length>1)?resp.data[k][2]:0) +  ((labelLength.length>2)?resp.data[k][3]:0);
                        }else{
                            obj[labelLength[0]] = resp.data[k][1];
                            if(labelLength.length>1)
                                obj[labelLength[1]] = resp.data[k][2];
                            if(labelLength.length>2)
                                obj[labelLength[2]] = resp.data[k][3];
                        }
                
                        maindata.push(obj);
                    }
                }
            }
        }
        return maindata;
    }

    public async multipleDevicesCostData(array,single,compareno,raw?){
        console.log("Cost Function called...");
        let maindata = [];
        console.log(array);
        
        for(let i=0;i<array.length;i++){
            let selectedPremise: any;
            console.log(this.commonService.getCompanyData());
            for (let l=0; l<eval(this.commonService.getCompanyData()[0].premises).length; l++) {
                for (let j=0; j<eval(eval(this.commonService.getCompanyData()[0].floor)[l]).length; j++) {
                    for (let k=0; k<eval(eval(eval(this.commonService.getCompanyData()[0].device_id)[l])[j]).length; k++) {
                        // console.log(eval(eval(eval(this.commonService.getCompanyData()[0].device_id)[l])[j])[k]);
                        if (eval(eval(eval(this.commonService.getCompanyData()[0].device_id)[l])[j])[k] == array[i].uuid) {
                            // console.log(eval(this.commonService.getCompanyData()[0].premises)[l]);
                            selectedPremise = eval(this.commonService.getCompanyData()[0].premises)[l];
                        }
                    }
                }
            }

            let curr = array[i].range_start;
            let timestamp_interval: any = new Array();
            timestamp_interval.push(curr);
            while (curr < array[i].range_end) {
                if ((curr += 6912000) < array[i].range_end) {
                    timestamp_interval.push(curr);
                } else {
                    timestamp_interval.push(array[i].range_end);
                    break;
                }
            }
            console.log(timestamp_interval);

            
            for (let x=1; x<timestamp_interval.length; x++) {
                const params = array[i];
                params.range_start = timestamp_interval[x-1];
                params.range_end = timestamp_interval[x]-3600;

                const resp:any = await this.httpService.getApi(params);

                if(!resp.type){
                    console.log("params for failed case "+params);
                    continue;
                }

                if (!!resp && !!resp.data) {
                  for (let i=0; i<resp.data.length; i++) {
                    for (let j=1; j<resp.data[i].length; j++) {
                      if (resp.data[i][j] == null) {
                        resp.data[i][j] = 0;
                      }
                    }
                  }
                }

                let data = await this.httpService.getParticularTariff(this.commonService.getCompanyData()[0].name, selectedPremise);
                if(!!data){
                    this.tariffData = data;
                    // console.log(this.tariffData);

                    let tempArr: any[] = new Array();
                    for (let i=0; i<this.tariffData.length; i++) {
                        if (this.tariffData[i].energy_type == "Grid") {
                            tempArr.push(this.tariffData[i]);
                        }
                    }
                    this.tariffData = tempArr;
                    this.selectedTariff = this.tariffData;

                    if (!!raw) {
                        this.rawData.push(resp.data);
                    }
                    let labelLength = resp.type.split(',');
                    if(i == 0){
                        for(let j=0;j<resp.data.length;j++){
                            let obj:any = {};

                            obj["unix"] = resp.data[j][0];
                            const date = new Date(resp.data[j][0]*1000);
                            let hours = date.getHours();
                            // console.log(hours);
                        
                            this.tariffIndex = -1;
                            for (let k=0; k<this.selectedTariff.length; k++) {
                                if (parseInt(resp.data[j][0]) >= parseInt(this.selectedTariff[k].date.toString().split('-')[0]) && parseInt(resp.data[j][0]) < parseInt(this.selectedTariff[k].date.toString().split('-')[1])) {
                                this.tariffIndex = k;
                                this.latestCurrency = this.selectedTariff[k].currency;
                                // console.log(this.tariffIndex);
                                break;
                                }
                            }
                            
                            if (this.tariffIndex == -1) {
                                this.commonService.presentConfirm("popup","Please add tariff for same date");
                                return;
                            }
                            
                            let xseries = !!compareno ? ("time"+compareno) : "time";
                            obj[xseries] = new Date(date.getFullYear(),date.getMonth(),date.getDate(),date.getHours(),date.getMinutes(),date.getSeconds());
                    
                            if(!!single){
                                if (hours >= this.selectedTariff[this.tariffIndex].peak_time.toString().split('-')[0] && hours < this.selectedTariff[this.tariffIndex].peak_time.toString().split('-')[1]) {
                                    let cost = this.selectedTariff[this.tariffIndex].slabs.toString().split("-")[0];
                                    obj["value"+compareno] = (resp.data[j][1] * cost) + ((labelLength.length>1)?(resp.data[j][2] * cost):0) +  ((labelLength.length>2)?(resp.data[j][3] * cost):0);
                                } else {
                                    let cost = this.selectedTariff[this.tariffIndex].slabs.toString().split("-")[1];
                                    obj["value"+compareno] = (resp.data[j][1] * cost) + ((labelLength.length>1)?(resp.data[j][2] * cost):0) +  ((labelLength.length>2)?(resp.data[j][3] * cost):0);                                    
                                }
                            }else{
                                if (hours >= this.selectedTariff[this.tariffIndex].peak_time.toString().split('-')[0] && hours < this.selectedTariff[this.tariffIndex].peak_time.toString().split('-')[1]) {
                                    obj[labelLength[0]] = resp.data[j][1] * this.selectedTariff[this.tariffIndex].slabs.toString().split("-")[0];
                                    if(labelLength.length>1)
                                    obj[labelLength[1]] = resp.data[j][2] * this.selectedTariff[this.tariffIndex].slabs.toString().split("-")[0];
                                    if(labelLength.length>2 )
                                    obj[labelLength[2]] = resp.data[j][3] * this.selectedTariff[this.tariffIndex].slabs.toString().split("-")[0];
                                } else {
                                    obj[labelLength[0]] = resp.data[j][1] * this.selectedTariff[this.tariffIndex].slabs.toString().split("-")[1];
                                    if(labelLength.length>1)
                                    obj[labelLength[1]] = resp.data[j][2] * this.selectedTariff[this.tariffIndex].slabs.toString().split("-")[1];
                                    if(labelLength.length>2 )
                                    obj[labelLength[2]] = resp.data[j][3] * this.selectedTariff[this.tariffIndex].slabs.toString().split("-")[1];                                    
                                }
                            }
                    
                            maindata.push(obj);
                        }
                    }else{
                        for(let j=0;j<resp.data.length;j++){
                            let obj:any = {};
                            
                            obj["unix"] = resp.data[j][0];
                            const date = new Date(resp.data[j][0]*1000);
                            let hours = date.getHours();
                            let time = new Date(date.getFullYear(),date.getMonth(),date.getDate(),date.getHours(),date.getMinutes(),date.getSeconds());
                        
                            this.tariffIndex = -1;
                            for (let k=0; k<this.selectedTariff.length; k++) {
                                if (parseInt(resp.data[j][0]) >= parseInt(this.selectedTariff[k].date.toString().split('-')[0]) && parseInt(resp.data[j][0]) < parseInt(this.selectedTariff[k].date.toString().split('-')[1])) {
                                this.tariffIndex = k;
                                this.latestCurrency = this.selectedTariff[k].currency;
                                // console.log(this.tariffIndex);
                                break;
                                }
                            }
                            
                            if (this.tariffIndex == -1) {
                                this.commonService.presentConfirm("popup","Please add tariff for same date");
                                return;
                            }
                        
                            let index = maindata.findIndex(obj =>  moment(obj.unix).isSame(resp.data[j][0]));
                            if(index >= 0){    
                                
                                if(!!single){
                                    if (hours >= this.selectedTariff[this.tariffIndex].peak_time.toString().split('-')[0] && hours < this.selectedTariff[this.tariffIndex].peak_time.toString().split('-')[1]) {
                                        let cost = this.selectedTariff[this.tariffIndex].slabs.toString().split("-")[0];
                                        maindata[index]["value"+compareno] = ((!!maindata[index]["value"+compareno]?maindata[index]["value"+compareno]:0) + (resp.data[j][1] * cost)) + ((labelLength.length>1)?(resp.data[j][2] * cost):0) +  ((labelLength.length>2)?(resp.data[j][3] * cost):0);
                                    } else {
                                        let cost = this.selectedTariff[this.tariffIndex].slabs.toString().split("-")[1];
                                        maindata[index]["value"+compareno] = ((!!maindata[index]["value"+compareno]?maindata[index]["value"+compareno]:0) + (resp.data[j][1] * cost)) + ((labelLength.length>1)?(resp.data[j][2] * cost):0) +  ((labelLength.length>2)?(resp.data[j][3] * cost):0);                                  
                                    }
                                }else{
                                    if (hours >= this.selectedTariff[this.tariffIndex].peak_time.toString().split('-')[0] && hours < this.selectedTariff[this.tariffIndex].peak_time.toString().split('-')[1]) {
                                        let cost = this.selectedTariff[this.tariffIndex].slabs.toString().split("-")[0];
                                        maindata[index][labelLength[0]] = (!!maindata[index][labelLength[0]]?maindata[index][labelLength[0]]:0) + (resp.data[j][1] * cost);
                                        if(labelLength.length>1)
                                            maindata[index][labelLength[1]] = (!!maindata[index][labelLength[1]]?maindata[index][labelLength[1]]:0) + (resp.data[j][2] * cost);
                                        if(labelLength.length>2)
                                            maindata[index][labelLength[2]] = (!!maindata[index][labelLength[2]]?maindata[index][labelLength[2]]:0) + (resp.data[j][3] * cost);
                                    } else {
                                        let cost = this.selectedTariff[this.tariffIndex].slabs.toString().split("-")[1];
                                        maindata[index][labelLength[0]] = (!!maindata[index][labelLength[0]]?maindata[index][labelLength[0]]:0) + (resp.data[j][1] * cost);
                                        if(labelLength.length>1)
                                            maindata[index][labelLength[1]] = (!!maindata[index][labelLength[1]]?maindata[index][labelLength[1]]:0) + (resp.data[j][2] * cost);
                                        if(labelLength.length>2)
                                            maindata[index][labelLength[2]] = (!!maindata[index][labelLength[2]]?maindata[index][labelLength[2]]:0) + (resp.data[j][3] * cost);                                
                                    }
                                }
                                
                            }else{
                                let xseries = !!compareno ? ("time"+compareno) : "time";
                                obj[xseries] = new Date(date.getFullYear(),date.getMonth(),date.getDate(),date.getHours(),date.getMinutes(),date.getSeconds());
                    
                                if(!!single){
                                    if (hours >= this.selectedTariff[this.tariffIndex].peak_time.toString().split('-')[0] && hours < this.selectedTariff[this.tariffIndex].peak_time.toString().split('-')[1]) {
                                        let cost = this.selectedTariff[this.tariffIndex].slabs.toString().split("-")[0];
                                        obj["value"+compareno] = (resp.data[j][1] * cost) + ((labelLength.length>1)?(resp.data[j][2] * cost):0) +  ((labelLength.length>2)?(resp.data[j][3] * cost):0);
                                    } else {
                                        let cost = this.selectedTariff[this.tariffIndex].slabs.toString().split("-")[1];
                                        obj["value"+compareno] = (resp.data[j][1] * cost) + ((labelLength.length>1)?(resp.data[j][2] * cost):0) +  ((labelLength.length>2)?(resp.data[j][3] * cost):0);                                
                                    }
                                }else{
                                    if (hours >= this.selectedTariff[this.tariffIndex].peak_time.toString().split('-')[0] && hours < this.selectedTariff[this.tariffIndex].peak_time.toString().split('-')[1]) {
                                        let cost = this.selectedTariff[this.tariffIndex].slabs.toString().split("-")[0];
                                        obj[labelLength[0]] = (resp.data[j][1] * cost);
                                        if(labelLength.length>1)
                                            obj[labelLength[1]] = (resp.data[j][2] * cost);
                                        if(labelLength.length>2)
                                            obj[labelLength[2]] = (resp.data[j][3] * cost);
                                    } else {
                                        let cost = this.selectedTariff[this.tariffIndex].slabs.toString().split("-")[1];
                                        obj[labelLength[0]] = (resp.data[j][1] * cost);
                                        if(labelLength.length>1)
                                            obj[labelLength[1]] = (resp.data[j][2] * cost);
                                        if(labelLength.length>2)
                                            obj[labelLength[2]] = (resp.data[j][3] * cost);                               
                                    }
                                }
                        
                                maindata.push(obj);
                            }
                        }
                    }
                }
            }
        }
        return maindata;
    }

    public async multipleData(dataFor,filters,data,compare,raw?){
        this.numberOfPhase = 0;
        if (!!raw) {
            this.rawData = new Array();
        }
        let mulData:any={obj1:"",obj2:""};
        let params = this.getParamsForDevice(filters,data,compare);
        let single = (!!filters.single && filters.single==true)?filters.single:false;
        if(!!compare){
            for(let i=0;i<params.length;i++){
                if (filters.type != "ec") {
                    mulData["obj"+(i+1)] = await this.multipleDevicesData(params[i],single,(i+1),raw);
                } else {
                    mulData["obj"+(i+1)] = await this.multipleDevicesCostData(params[i],single,(i+1));
                }
            }
        }else{
            if (filters.type != "ec") {
                mulData.obj1 = await this.multipleDevicesData(params,single,null, raw);
            } else {
                mulData.obj1 = await this.multipleDevicesCostData(params,single,null, raw);
            }
        }

        mulData.obj1 = !!mulData.obj1?this.commonService.dateAlignment(mulData.obj1):"";
        mulData.obj2 = !!mulData.obj2?this.commonService.dateAlignment(mulData.obj2):"";
        return mulData;
    }

    public getParamsForPlace(){

    }

    public getParamsForPremise(){
        
    }
    
    public getParamsForDevice(filters,data,compare){
        // console.log("main obj is "+data)
        let array = [];
        let compareArray = [];
        if(!!compare){
            let count = (!!data["obj2"] && !!data["obj2"].device && data["obj2"].device.length>0) ? 3 : 2;
            for(let i=1;i<count;i++){
                let array = [];
                for(let j=0;j<data["obj"+i].device.length;j++){ //for places and premises
                    let type = this.formType(filters,data["obj"+i].phase[j]);
                    array.push({uuid:data["obj"+i].device[j],measurement:filters.measurement,range_start:filters.range_start,range_end:filters.range_end,type:type,aggregation:filters.aggregation,timezone:filters.timezone});
                }
                compareArray.push(array);
            }
        }else{
            for(let i=0;i<data.length;i++){
                array.push({uuid:data[i],measurement:filters.measurement,range_start:filters.range_start,range_end:filters.range_end,type:filters.type,aggregation:filters.aggregation,timezone:filters.timezone});
            }
        }
        // array[1] = {uuid:'rc000010',measurement:'power',range_start:'1605958440',range_end:'1605958840',type:'u1,u2,u3',aggregation:''};
        return (!!compare?compareArray:array);
    }

    public formType(filters,phase){
        let type;

        if (phase == '1+2+3') {
            this.numberOfPhase += 3;
        } else {
            this.numberOfPhase += 1;
        }

        if (filters.type == "freq") {
            type = "freq";
        }
        else if(phase == '1st'){
            if (filters.type != "ec") {
                type = filters.type + '1';
            } else {
                type = "em1";
            }
        }else if(phase == '2nd'){
            if (filters.type != "ec") {
                type = filters.type + '2';
            } else {
                type = "em2";
            }
        }else if(phase == '3rd'){
            if (filters.type != "ec") {
                type = filters.type + '3';
            } else {
                type = "em3";
            }
        }else if(phase == '1+2+3'){
            for(let i=0;i<3;i++){
                if(i == 0){
                    if (filters.type != "ec") {
                        type = filters.type + (i+1);
                    } else {
                        type = "em" + (i+1);
                    }
                }else{
                    if (filters.type != "ec") {
                        type = type + "," + filters.type + (i+1);
                    } else {
                        type = type + ",em" + (i+1);
                    }
                }
            }
        }
        // console.log("type is "+type);
        return type;
    }

}