import { Component, OnInit, Input, } from '@angular/core';
import * as d3 from 'd3';
@Component({
  selector: 'app-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.scss']
})
export class PieComponent implements OnInit {
  private arc: any;
  private hoveredArc: any;
  private arcLabel: any;
  private pie: any;
  private slices: any;
  private texts: any;
  public tooltip: any;
  private svg;
  private mainContainer: any;
  private dataSource: any;
  private color: any;
  private margin:any= { top: 20, right: 100, bottom: 30, left: 40 };
  private width = 1060 - this.margin.left - this.margin.right;
  private height = 400 - this.margin.top - this.margin.bottom;
  private radius = Math.min(this.width, this.height) / 2;
  @Input("data") public data: any;

  constructor() { }

  ngOnInit() {
    this.renderPie();
  }

  private renderPie() {
    this.dataSource = this.data;
    this.svg = d3.select('#pie').select('svg').attr(
      "viewBox",
      `0 0 ${this.width + this.margin.left + this.margin.right} ${this.height +
        this.margin.top +
        this.margin.bottom}`
    )

    this.tooltip = d3.select('#pie')
      .append('div')
      .attr('class', 'tooltip')
      .style('display', 'none')
      .style('opacity', 0);
      
    this.initPieSvg();
  }

  private initPieSvg() {
    this.setSVGDimensionsPie();

    // Color scale.
    this.color = d3.scaleOrdinal(d3.schemeCategory10);

    this.mainContainer = this.svg.append('g')
      .attr('transform', 'translate(' + (this.radius) + ',' + (this.radius) + ')');

    this.pie = d3.pie()
      .sort(null)
      .value((d: any) => d.Rating);

    this.drawPie();
    this.resizepie();
  }

  private setSVGDimensionsPie() {
    this.radius = (Math.min(this.width, this.height)) / 2;

    this.svg.select('g')
      .attr('transform', 'translate(' + this.radius + ',' + this.radius + ')');
  }

  private drawPie() {
    this.setArcs();
    this.drawSlices();
    this.drawLabelsPie();
  }

  private setArcs() {
    const thickness = (1 - 25 / 100);
    this.arc = d3.arc()
      .outerRadius(this.radius)
      .innerRadius(0);
      //.innerRadius(this.radius * thickness); //for donut chart

    this.arcLabel = d3.arc()
      .innerRadius(this.radius * .8)
      .outerRadius(this.radius * .8);
  }

  private drawSlices() {
    this.slices = this.mainContainer.selectAll('path')
      .remove()
      .exit()
      .data(this.pie(this.dataSource))
      .enter().append('g')
      .append('path')
      .attr('d', this.arc);
      
    this.slices
      .attr('fill', 'transparent')
      .attr('fill', (d, i) => this.color(i));

    this.slices
    .attr('fill', (d, i) => this.color(i))
    .on('mousemove', function (s) {
      const data = s.currentTarget.__data__;
      this.tooltip.style('top', (s.layerY + 15) + 'px').style('left', (s.layerX) + 'px')
      .style('display', 'block').style('opacity', 1).style('height', '60px')
      this.tooltip.html(`Unit: ${data.data.Framework}<br>Consume: ${data.data.Rating}<br>`);
    }.bind(this))
    .on('mouseout', function () {
      this.tooltip.style('display', 'none').style('opacity', 0);
    }.bind(this));
  }

  private drawLabelsPie() {
    console.log("inside drawLabelsPie");
    this.texts = this.mainContainer.selectAll('text')
      .remove()
      .exit()
      .data(this.pie(this.dataSource))
      .enter().append('text')
      .attr('text-anchor', 'middle')
      .attr('transform', d => `translate(${this.arcLabel.centroid(d)})`)
      .attr('dy', '0.35em');

    this.texts.append('tspan')
      .filter(d => (d.endAngle - d.startAngle) > 0.05)
      .attr('x', 0)
      .attr('y', 0)
      .style('font-weight', 'bold')
      .text(d => d.data.Framework);

    this.texts.append('tspan')
      .filter(d => (d.endAngle - d.startAngle) > 0.25)
      .attr('x', 0)
      .attr('y', '1.3em')
      .attr('fill-opacity', 0.7)
      .text(d => d.data.Rating.toLocaleString());
  }

  private resizepie() {
    this.setArcs();
    this.repaintpie();
    this.drawLabelsPie();
  }

  private repaintpie() {
    this.drawSlices();
    this.drawLabelsPie();
  }

}
