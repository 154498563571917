import { Component, Input, OnInit} from '@angular/core';
import { CommonService } from '../../services/commonservice.service';
import { ConfirmService } from './confirm-box.service';
const CONFIRM_MESSAGE = 'Loading...';
@Component({
    selector: 'app-confirm',
    templateUrl: './confirm-box.page.html',
    styleUrls: ['./confirm-box.page.scss']
})
export class ConfirmComponent implements OnInit {
    @Input() show: boolean;
    showPopUp = false;
    LOADING_TEXT: string;
    message:any;
    nmode = true;
    constructor(private confirm: ConfirmService,public commonService:CommonService) {
        console.log("inside confirm cons")
        //this function waits for a message from alert service, it gets   
        //triggered when we call this from any other component  
        this.confirm.getMessage().subscribe(data => {  
            console.log(data);
            this.message = data;  
            this.nmode = (localStorage.getItem('mode')=="true")?true:false;
        }); 
    }

    ngOnInit() {
        console.log("inside confirm page")
       //this function waits for a message from alert service, it gets   
        //triggered when we call this from any other component  
        this.confirm.getMessage().subscribe(data => {  
            console.log(data);
            this.message = data;  
            this.nmode = (localStorage.getItem('mode')=="true")?true:false;
        }); 
    }
}