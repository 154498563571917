import { Component, OnInit} from '@angular/core';
import { CommonService } from './common/services/commonservice.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  nmode=true;

  constructor(public commonService:CommonService){}

  ngOnInit(): void {
    this.commonService.nightmode.subscribe(data => {
      if (!!data) {
        this.nmode = true;
      } else {
        this.nmode = false;
      }
    });
  }

}
