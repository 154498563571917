import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import { Console } from 'console';
import * as moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { first } from 'rxjs/operators';
import { CommonService } from '../common/services/commonservice.service';
import { HttpService } from '../common/services/httpservice.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  @Input() page:any;
  @Input() filterObj:any = {uuid:"",type:"",graphType:"",range:"",dateTo:"",dateFrom:"",dateTo2:"",dateTo3:"",dateTo4:"",date:"",timeFrom:"",timeTo:"",minutesFrom:"",minutesTo:"",stack:"",measurement:""};
  @Output() filteredParams = new EventEmitter<string>();
  graphType=[{code:"line",value:"Line"},{code:"bar",value:"Bar"},{code:"donut",value:"Pie"}];
  uuidArr: any = [{code:"",value:""}];
  typeArr=[{code:"",value:""}];
  rangeArr=[{code:"Last Week",value:"Last Week"},{code:"This Week",value:"This Week"}];
  stackArr=[{code:"yes",value:"Yes"},{code:"no",value:"No"}];
  timeArr=[{code:"00",value:"12 AM"},{code:"01",value:"1 AM"},{code:"02",value:"2 AM"},{code:"03",value:"3 AM"},{code:"04",value:"4 AM"},{code:"05",value:"5 AM"},{code:"06",value:"6 AM"},{code:"07",value:"7 AM"},{code:"08",value:"8 AM"},{code:"09",value:"9 AM"},{code:"10",value:"10 AM"},{code:"11",value:"11 AM"},{code:"12",value:"12 PM"},{code:"13",value:"1 PM"},{code:"14",value:"2 PM"},{code:"15",value:"3 PM"},{code:"16",value:"4 PM"},{code:"17",value:"5 PM"},{code:"18",value:"6 PM"},{code:"19",value:"7 PM"},{code:"20",value:"8 PM"},{code:"21",value:"9 PM"},{code:"22",value:"10 PM"},{code:"23",value:"11 PM"}];
  minutesArr=[{code:"00",value:"00"},{code:"01",value:"01"},{code:"02",value:"02"},{code:"03",value:"03"},{code:"04",value:"04"},{code:"05",value:"05"},{code:"06",value:"06"},{code:"07",value:"07"},{code:"08",value:"08"},{code:"09",value:"09"},{code:"10",value:"10"},{code:"11",value:"11"},{code:"12",value:"12"},{code:"13",value:"13"},{code:"14",value:"14"},{code:"15",value:"15"},{code:"16",value:"16"},{code:"17",value:"17"},{code:"18",value:"18"},{code:"19",value:"19"},{code:"20",value:"20"},{code:"22",value:"22"},{code:"22",value:"22"},{code:"23",value:"23"},{code:"24",value:"24"},{code:"25",value:"25"},{code:"26",value:"26"},{code:"27",value:"27"},{code:"28",value:"28"},{code:"29",value:"29"},{code:"30",value:"30"},{code:"33",value:"33"},{code:"33",value:"33"},{code:"33",value:"33"},{code:"34",value:"34"},{code:"35",value:"35"},{code:"36",value:"36"},{code:"37",value:"37"},{code:"38",value:"38"},{code:"39",value:"39"},{code:"40",value:"40"},{code:"44",value:"44"},{code:"44",value:"44"},{code:"44",value:"44"},{code:"44",value:"44"},{code:"45",value:"45"},{code:"46",value:"46"},{code:"47",value:"47"},{code:"48",value:"48"},{code:"49",value:"49"},{code:"50",value:"50"},{code:"55",value:"55"},{code:"55",value:"55"},{code:"55",value:"55"},{code:"55",value:"55"},{code:"55",value:"55"},{code:"56",value:"56"},{code:"57",value:"57"},{code:"58",value:"58"},{code:"59",value:"59"}];
  dropdownSettings:IDropdownSettings =  {singleSelection: false,idField: 'code',textField: 'value',itemsShowLimit: 3,allowSearchFilter: true,closeDropDownOnSelection: false,enableCheckAll: false};
  dropdownSettings1:IDropdownSettings = {singleSelection: true,idField: 'code',textField: 'value',itemsShowLimit: 1,allowSearchFilter: true,closeDropDownOnSelection: true,enableCheckAll: false};
  dropdownSettings2:IDropdownSettings = {singleSelection: false,idField: 'id',textField: 'value',itemsShowLimit: 1,allowSearchFilter: true,closeDropDownOnSelection: false,enableCheckAll: false};
  hidecomparison = false;
  hideforecasting = false;
  hideenergycost = false;
  hidebaseline = false;
  hideconsumption = false;
  dropSetting = this.dropdownSettings;
  loginData: any;
  loggedUser: any;
  userIndex: number;
  tariffData: any;
  isMobile = false;
  constructor( public commonService: CommonService, public httpService: HttpService ) { }

  async ngOnInit() {
    console.log("filter page called");
    this.isMobile = this.commonService.getIsMobile();
    this.typeArr = this.commonService.getFilterType();
    if(this.page == "consumption"){
      this.hideconsumption = true;
    }
    if(this.page == "comparison"){
      this.hidecomparison = true;
    }
    if(this.page == "forecasting"){
      this.hideforecasting = true;
    }
    if (this.page == "baseline") {
      this.hidebaseline = true;
      this.dropdownSettings2= {singleSelection: true,idField: 'id',textField: 'value',itemsShowLimit: 1,allowSearchFilter: true,closeDropDownOnSelection: true,enableCheckAll: false};
    }
    if(this.page == "energycost") {
      this.hideenergycost = true;
      this.dropdownSettings2= {singleSelection: true,idField: 'id',textField: 'value',itemsShowLimit: 1,allowSearchFilter: true,closeDropDownOnSelection: true,enableCheckAll: false};
      
      let companyName: String;
      if (this.commonService.getloggedData().role != "Superadmin") {
        companyName = this.commonService.getloggedData().company;
      } else {
        companyName = this.commonService.getPlaceCompany().value;
      }
      
      let data = await this.httpService.getParticularTariff(companyName, this.commonService.getPlacePremise().value);
      if(!!data){  
        this.tariffData = data;
        this.commonService.energyCostTariffs = this.tariffData;
        console.log(this.tariffData);

          let typeArr: any = new Array();
          let tempArr: any = new Array();
          for (let i=0; i<this.tariffData.length; i++) {
            if (!tempArr.includes(this.tariffData[i].energy_type)) {
              typeArr.push({
                code: this.tariffData[i].energy_type,
                value: this.tariffData[i].energy_type
              });
              tempArr.push(this.tariffData[i].energy_type);
            }
          }
          this.typeArr = typeArr;
        }
      //   ,error => {
      //     alert("Error in fetching tariff plans...")
      //   }
      // );
    }
    if(this.page == "comparison" || this.page == "forecasting" || this.page == "consumption"){//for single selection of u1,u2.....
      this.dropSetting = this.dropdownSettings1;
      this.graphType=[{code:"line",value:"Line"},{code:"bar",value:"Bar"}];
    }
    if(this.page == "places" || this.page == "consumption" || this.page == "comparison" || this.page == "energycost" || this.page == "baseline" || this.page == "forecasting" || this.page == "report") {
      this.filterObj["premise"] = this.commonService.getPlacePremise().value;

      this.uuidArr = new Array();
      // console.log(this.commonService.getPlacePremise());
      // console.log(this.commonService.getCompanyData());

      let num = 0;
      if (this.commonService.getloggedData().role != "Superadmin") {
        let premises: any = this.commonService.getCompanyData()[0].premises;
        let devices: any = this.commonService.getCompanyData()[0].device_name;
        let devicesId: any = this.commonService.getCompanyData()[0].device_id;
        let devicePhase: any = this.commonService.getCompanyData()[0].device_phase;
      
        for (let i=0; i<eval(premises).length; i++) {        
          if (eval(premises)[i] == this.commonService.getPlacePremise().value) {
            // console.log(eval(premises)[i]);
            // console.log(eval(devices)[i]);

            for (let j=0; j<eval(devices)[i].length; j++) {
              for (let k=0; k<eval(eval(devices)[i])[j].length; k++) {
                // console.log(eval(eval(eval(devices)[i])[j])[k]);
                this.uuidArr.push({
                  id: num++,
                  code: eval(eval(eval(devicesId)[i])[j])[k],
                  value: eval(eval(eval(devices)[i])[j])[k],
                  phase: eval(eval(eval(devicePhase)[i])[j])[k]
                });
              }          
            }
          }
        }
      }
      else {
        let companyData: any = this.commonService.getCompanyData();

        let num = 0;
        for (let a=0; a<companyData.length; a++) {
          if (this.commonService.getCompanyData()[a].name == this.commonService.getPlaceCompany().value) {
            let premises: any = this.commonService.getCompanyData()[a].premises;
            let devices: any = this.commonService.getCompanyData()[a].device_name;
            let devicesId: any = this.commonService.getCompanyData()[a].device_id;
            let devicePhase: any = this.commonService.getCompanyData()[a].device_phase;
          
            for (let i=0; i<eval(premises).length; i++) {        
              if (eval(premises)[i] == this.commonService.getPlacePremise().value) {
                // console.log(eval(premises)[i]);
                // console.log(eval(devices)[i]);

                for (let j=0; j<eval(devices)[i].length; j++) {
                  for (let k=0; k<eval(eval(devices)[i])[j].length; k++) {
                    // console.log(eval(eval(eval(devices)[i])[j])[k]);
                    this.uuidArr.push({
                      id: num++,
                      code: eval(eval(eval(devicesId)[i])[j])[k],
                      value: eval(eval(eval(devices)[i])[j])[k],
                      phase: eval(eval(eval(devicePhase)[i])[j])[k]
                    });
                  }          
                }
              }
            }
          }
        }
      }
      // console.log(this.uuidArr);
    }
  }

  public async apply() {
    let data:any =  this.filterData();
    this.filteredParams.emit(data);
  }

  public filterData(){
    let tempData:any = JSON.parse(JSON.stringify(this.filterObj));
    if(!!tempData.uuid && tempData.uuid.length>0){
      if(tempData.uuid.length>1){
        let mainObj = {obj1: {place:"", premise: "", device: [], phase: [], devicename: []}, obj2: {place:"", premise: "", device: [], phase: [], devicename: []}};
        for (let i=0; i<tempData.uuid.length; i++) {
          for (let j=0; j<this.uuidArr.length; j++) {
            if (this.uuidArr[j].id == tempData.uuid[i].id) {
              mainObj.obj1.device.push(this.uuidArr[j].code);
              mainObj.obj1.phase.push(this.uuidArr[j].phase);
              mainObj.obj1.devicename.push(this.uuidArr[j].value);
              break;
            }
          }
        }
        tempData.mainObj = mainObj;
      }else{
        for (let i=0; i<this.uuidArr.length; i++) {
          if (this.uuidArr[i].id == tempData.uuid[0].id) {
            tempData.uuid = this.uuidArr[i].code;
            break;
          }
        }
      }
    }

    if(!!tempData.stack && tempData.stack.length>0){
      tempData.stack = tempData.stack;
    }else{
      tempData.stack = [];
      tempData.stack.push({code:'yes',value:'Yes'});
    }

    tempData.range = (!!tempData.range && tempData.range.length>0)?tempData.range[0].code:null;
    let arr = [];
    if(!!tempData.type && tempData.type.length > 0){
      for(let i=0;i<tempData.type.length;i++){
        arr.push(tempData.type[i].code);
      }
      tempData.type = arr.toString();
    }

    let dateObj = this.dateconversion(tempData);
    tempData.range_start = dateObj.rangestart;
    tempData.range_end = dateObj.rangeend;
    if(this.page == "comparison"){ //for comparison
      tempData.range_end2 = (!!dateObj.rangeend2)?dateObj.rangeend2:null;
      tempData.range_end3 = (!!dateObj.rangeend3)?dateObj.rangeend3:null;
      tempData.range_end4 = (!!dateObj.rangeend4)?dateObj.rangeend4:null;
    }
    tempData.measurement = (!!tempData.type && (tempData.type.includes("u") || tempData.type.includes("p")))?'power':'energy';

    console.log(tempData);
    return tempData;
  }

  public dateconversion(data){
    let dateObj:any = {};
    let dateFrom = (!!data.dateFrom && !!data.dateFrom.month)?data.dateFrom.month+"/"+data.dateFrom.day+"/"+data.dateFrom.year:null;
    let dateTo = (!!data.dateTo && !!data.dateTo.month)?data.dateTo.month+"/"+data.dateTo.day+"/"+data.dateTo.year:'current';

    let timeFrom,timeTo;
    if(!!data.timeFrom && data.timeFrom.length>0){
      if(!!data.minutesFrom && data.minutesFrom.length>0)
        timeFrom = data.timeFrom[0].code+":"+data.minutesFrom[0].code+":00";
      else
        timeFrom = data.timeFrom[0].code+":00:00";
    }else{
      timeFrom = "00:00:00";
    }
    if(!!data.timeTo && data.timeTo.length>0){
      if(!!data.minutesTo && data.minutesTo.length>0)
        timeTo = data.timeTo[0].code+":"+data.minutesTo[0].code+":00";
      else
        timeTo = data.timeTo[0].code+":00:00";
    }else{
      timeTo = "23:59:00";
    }

    if (this.page == "energycost") {
      timeFrom = "00:00:00";
      timeTo = "00:00:00";
    }

    let re2=null,re3=null,re4=null;
    if(this.page == "comparison"){ //for comparison
      if(!!data.dateTo2){
        if (dateTo != 'current') {
          let dateTo2 = data.dateTo2.month+"/"+data.dateTo2.day+"/"+data.dateTo2.year;
          let end = dateTo2+" "+"23:59:00";
          re2 = (moment(end, "M/D/YYYY HH:mm:ss").valueOf())/1000;
        } else {
          re2 = Math.floor(Date.now() / 1000);
        }
      }
      if(!!data.dateTo3){ 
        if (dateTo != 'current') {
          let dateTo3 = data.dateTo3.month+"/"+data.dateTo3.day+"/"+data.dateTo3.year;
          let end = dateTo3+" "+"23:59:00";
          re3 = (moment(end, "M/D/YYYY HH:mm:ss").valueOf())/1000;
        } else {
          re3 = Math.floor(Date.now() / 1000);
        }
      }
      if(!!data.dateTo4){ 
        if (dateTo != 'current') {
          let dateTo4 = data.dateTo4.month+"/"+data.dateTo4.day+"/"+data.dateTo4.year;
          let end = dateTo4+" "+"23:59:00";
          re4 = (moment(end, "M/D/YYYY HH:mm:ss").valueOf())/1000;
        } else {
          re4 = Math.floor(Date.now() / 1000);
        }
      }
    }

    let start = dateFrom+" "+timeFrom;
    let end = dateTo+" "+timeTo;
    let rs = (moment(start, "M/D/YYYY HH:mm:ss").valueOf())/1000;
    let re: any;

    if (dateTo != 'current') {
      re = (moment(end, "M/D/YYYY HH:mm:ss").valueOf())/1000;
    } else {
      re = Math.floor(Date.now() / 1000);
    }
    // console.log(rs);
    // console.log(re);
    dateObj = {rangestart:rs, rangeend:re};
    if(this.page == "comparison"){ //for comparison
      dateObj = {rangestart:rs, rangeend:re,rangeend2:re2, rangeend3:re3, rangeend4:re4};
    }
    return dateObj;
  }

  public closePopup(){
    this.filteredParams.emit(null);
  }

  public onDeviceSelect(event) {
    // console.log(event);
    let phase: any;
    this.filterObj.type = [];

    for (let i=0; i<this.uuidArr.length; i++) {
      if (this.uuidArr[i].id == event.id) {
        phase = this.uuidArr[i].phase;
        break;
      }
    }

    if(this.page != "energycost" && this.page != "forecasting"){
      this.typeArr = new Array();
      if (phase == "1st") {
        let num: String = "1";
        this.typeArr = [
          {code:"p"+num,value:"Power (W)"},
          {code:"em"+num,value:"Energy (kWh)"},
          {code:"es"+num,value:"Apparent Energy (kVAh)"},
          {code:"i"+num,value:"Current (A)"},
          {code:"u"+num,value:"Voltage (V)"},
          {code:"pf"+num,value:"Power Factor"},
          {code:"freq",value:"Frequency (Hz)"},
          {code:"rq"+num,value:"Reactive Power (VAR)"},
          {code:"p_appr"+num,value:"Apparent Power (VA)"},
          {code:"hi"+num,value:"Harmonics Current (A)"},
          {code:"hu"+num,value:"Harmonics Voltage (V)"},
          {code:"thdu"+num,value:"Total Voltage Harmonics Distortion (%)"},
          {code:"thdi"+num,value:"Total Current Harmonics Distortion (%)"},
          {code:"yu"+num,value:"Voltage Phase Angle (\u00B0)"},
          {code:"yi"+num,value:"Current Phase Angle (\u00B0)"}
        ];
      } else if (phase == "2nd") {
        let num: String = "2";
        this.typeArr = [
          {code:"p"+num,value:"Power (W)"},
          {code:"em"+num,value:"Energy (kWh)"},
          {code:"es"+num,value:"Apparent Energy (kVAh)"},
          {code:"i"+num,value:"Current (A)"},
          {code:"u"+num,value:"Voltage (V)"},
          {code:"pf"+num,value:"Power Factor"},
          {code:"freq",value:"Frequency (Hz)"},
          {code:"rq"+num,value:"Reactive Power (VAR)"},
          {code:"p_appr"+num,value:"Apparent Power (VA)"},
          {code:"hi"+num,value:"Harmonics Current (A)"},
          {code:"hu"+num,value:"Harmonics Voltage (V)"},
          {code:"thdu"+num,value:"Total Voltage Harmonics Distortion (%)"},
          {code:"thdi"+num,value:"Total Current Harmonics Distortion (%)"},
          {code:"yu"+num,value:"Voltage Phase Angle (\u00B0)"},
          {code:"yi"+num,value:"Current Phase Angle (\u00B0)"}
        ];
      } else if (phase == "3rd") {
        let num: String = "3";
        this.typeArr = [
          {code:"p"+num,value:"Power (W)"},
          {code:"em"+num,value:"Energy (kWh)"},
          {code:"es"+num,value:"Apparent Energy (kVAh)"},
          {code:"i"+num,value:"Current (A)"},
          {code:"u"+num,value:"Voltage (V)"},
          {code:"pf"+num,value:"Power Factor"},
          {code:"freq",value:"Frequency (Hz)"},
          {code:"rq"+num,value:"Reactive Power (VAR)"},
          {code:"p_appr"+num,value:"Apparent Power (VA)"},
          {code:"hi"+num,value:"Harmonics Current (A)"},
          {code:"hu"+num,value:"Harmonics Voltage (V)"},
          {code:"thdu"+num,value:"Total Voltage Harmonics Distortion (%)"},
          {code:"thdi"+num,value:"Total Current Harmonics Distortion (%)"},
          {code:"yu"+num,value:"Voltage Phase Angle (\u00B0)"},
          {code:"yi"+num,value:"Current Phase Angle (\u00B0)"}
        ];
      } else {
        this.typeArr = [
          {code:"p1,p2,p3",value:"Power (W)"},
          {code:"em1,em2,em3",value:"Energy (kWh)"},
          {code:"es1,es2,es3",value:"Apparent Energy (kVAh)"},
          {code:"i1,i2,i3",value:"Current (A)"},
          {code:"u1,u2,u3",value:"Voltage (V)"},
          {code:"pf1,pf2,pf3",value:"Power Factor"},
          {code:"freq",value:"Frequency (Hz)"},
          {code:"rq1,rq2,rq3",value:"Reactive Power (VAR)"},
          {code:"p_appr1,p_appr2,p_appr3",value:"Appearent Power (VA)"},
          {code:"hi1,hi2,hi3",value:"Harmonics Current (A)"},
          {code:"hu1,hu2,hu3",value:"Harmonics Voltage (V)"},
          {code:"thdu1,thdu2,thdu3",value:"Total Voltage Harmonics Distortion (%)"},
          {code:"thdi1,thdi2,thdi3",value:"Total Current Harmonics Distortion (%)"},
          {code:"yu1,yu2,yu3",value:"Voltage Phase Angle (\u00B0)"},
          {code:"yi1,yi2,yi3",value:"Current Phase Angle (\u00B0)"}
        ];
      }
    }
    else if (this.page == "energycost") {
      if (phase == "1st") {
        let num: String = "1";
        this.commonService.setFilterEnergyType([{code:"em"+num,value:"Energy"}]);
      } else if (phase == "2nd") {
        let num: String = "2";
        this.commonService.setFilterEnergyType([{code:"em"+num,value:"Energy"}]);
      } else if (phase == "3rd") {
        let num: String = "3";
        this.commonService.setFilterEnergyType([{code:"em"+num,value:"Energy"}]);
      } else {
        this.commonService.setFilterEnergyType([{code:"em1,em2,em3",value:"Energy"}]);
      }
    } 
    else {
      this.typeArr = new Array();
      if (phase == "1st") {
        let num: String = "1";
        this.typeArr = [{code:"em"+num,value:"Energy"}];
      } else if (phase == "2nd") {
        let num: String = "2";
        this.typeArr = [{code:"em"+num,value:"Energy"}];
      } else if (phase == "3rd") {
        let num: String = "3";
        this.typeArr = [{code:"em"+num,value:"Energy"}];
      } else {
        this.typeArr = [{code:"em1,em2,em3",value:"Energy"}];
      }
    }
    // console.log(this.typeArr);
    this.commonService.setFilterType(this.typeArr);
  }

}
