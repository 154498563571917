import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OverviewComponent } from './overview.page';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GraphsModule } from '../graphs/graph.module';
import { ChartsModule } from 'ng2-charts';
import { AgmCoreModule } from '@agm/core';

const routes:Routes = [
  {
    path: '',
    component: OverviewComponent
  }
]

@NgModule({
  declarations: [
    OverviewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    GraphsModule,
    ChartsModule,
    NgMultiSelectDropDownModule,
    AgmCoreModule.forRoot({apiKey: 'AIzaSyCxR-k6IMJ-H8oeL4-TNUcBdmxCgHL_lvQ'}),
    RouterModule.forChild(routes)
  ],
})
export class OverviewModule { }
