import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Color } from 'ng2-charts';
import { ConfirmService } from '../components/confirm-box/confirm-box.service';
import { HttpService } from './httpservice.service';
import { first } from 'rxjs/operators';
import { LoginService } from 'src/app/login/loginService.service';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root' 
})
export class CommonService {

    public nightmode = new Subject<any>();
    public tabSize = new Subject<any>();
    public tabColor = new Subject<any>();
    public refresh = new Subject<any>();

    loginData: any;
    roleData: any;
    roleedit:any;
    useredit:any;
    actions:any;
    loggedData:any;
    index=0;
    colors: Color[];
    graphData:any;
    masterdata:any;
    loggedUserCompanyData: Object;
    deviceData: any;
    placePremise: any;
    placeCompany: any;
    updateStatus: boolean;
    filterType: any;
    filterEnergyType: any;
    energyCostTariffs: any;
    isMobile:any;
    constructor(private confirm: ConfirmService, private httpService: HttpService, private loginService: LoginService){}

    setNightMode(){
        let self = this;
        let mode = (localStorage.getItem('mode')=="true")?false:true;
        self.nightmode.next(mode);
        let mode1 = (mode==true)?"true":"false";
        localStorage.setItem('mode',mode1);
    }

    setTabSize(){
        let self = this;
        let mode = (localStorage.getItem('tab')=="true")?false:true;
        self.tabSize.next(mode);
        let mode1 = (mode==true)?"true":"false";
        localStorage.setItem('tab',mode1);
    }

    refreshdata(data){
        let self = this;
        self.refresh.next(data);
    }

    presentConfirm(type,message){
        let self = this;
        let promise = new Promise((resolve,reject)=>{
            self.confirm.confirmThis(type,message, function () {  
                // console.log("Yes clicked");  
                resolve(true);
            }, function () {  
                // console.log("No clicked");  
                resolve(false);
            })
        });
        return promise;
    }

    setTabColor(data){
        let self = this;
        self.tabColor.next(data);
    }

    setLoginData(){
        if(this.index == 0){
            this.index = 1;//for adding master data only once during login page call
            // this.loginData=[
            //     {name:"Rohit",password:"1234",role:"Admin",logindate:"1-3-2020 09:11:11"},
            //     {name:"Virat",password:"1234",role:"Superadmin",logindate:"2-3-2020 02:16:12"},
            //     {name:"Ajinkya",password:"1234",role:"User",tabsAllowed:"baseline,forecasting,operating,energycost,comparison",logindate:"3-3-2020 01:15:13"},
            //     {name:"Rahul",password:"1234",role:"User",tabsAllowed:"report,alerts,settings",logindate:"4-3-2020 05:12:14"},
            // ];

            this.httpService.getUsers().pipe(first())
            .subscribe(
                data => {
                    this.loginData = data;
                },
                error => {
                    alert("Error in fetching user data...");
                }
            );
        }
    }

    updateLoginData(data){
        this.loginData = data;
    }

    getLoginData(){
        return this.loginData;
    }

    updateSession() {
        this.updateStatus = false;
        let id = this.loggedData.id;
        // console.log(id);

        this.httpService.getUsers().pipe(first())
        .subscribe(
            data => {
                this.loginData = data;
                // console.log(this.loginData);
                
                for (let i=0; i<this.loginData.length; i++) {
                    if (this.loginData[i].id == id) {
                        this.loggedData = this.loginData[i];
                        // console.log(this.loggedData);
                        break;
                    }
                }

                if (this.loggedData.role == 'Superadmin') {
                    this.httpService.getCompanyNames().pipe(first())
                    .subscribe(
                        data => {
                            this.loggedUserCompanyData = data;
                        },
                        error => {
                            alert("Error in fetching superadmin company names...")
                        });
                }
                else {
                    this.httpService.getParticularCompany(this.loggedData.company).pipe(first())
                    .subscribe(
                        data => {
                            this.loggedUserCompanyData = data;
                        },
                        error => {
                            alert("Error in fetching admin/user company names...")
                        });
                }
                // console.log(this.loggedUserCompanyData);
            },
            error => {
                alert("Error in fetching user data...");
            }
        );
        console.log("Update Complete...");
    }

    setCompanyData(index: any, role: any) {
        let promise = new Promise((resolve,reject)=>{
            if (role == 'Superadmin') {
                this.httpService.getCompanyNames().pipe(first())
                .subscribe(data => {
                    this.loggedUserCompanyData = data;
                    resolve(true);
                },error => {
                    resolve(false);
                    alert("Error in fetching superadmin company names...")
                });
            }
            else {
                // console.log(this.loginData[index].company);
                this.httpService.getParticularCompany(this.loginData[index].company).pipe(first())
                .subscribe(data => {
                    this.loggedUserCompanyData = data;
                    resolve(true);
                },error => {
                    resolve(false);
                    alert("Error in fetching admin/user company names...")
                });
            }
        });
        return promise;
    }

    getCompanyData() {
        return this.loggedUserCompanyData;
    }

    setRoleData(){
        this.roleData = [
            {name:"Admin",actions:["Master"],updateon:"1-3-2020 09:13:11"},
            {name:"Superadmin",actions:["Master"],updateon:"2-3-2020 10:16:12"},
            {name:"User",actions:["User"],updateon:"3-3-2020 11:18:13"}
        ];
    }

    getRoleData(){
        return this.roleData;
    }

    setActions(){
        this.actions = [
            {name:"Master"},
            {name:"Settings"},
            {name:"User"}
        ];
    }

    getActions(){
        return this.actions;
    }

    setRoleEdit(data){
        this.roleedit = data;
    }

    getRoleEdit(){
        return this.roleedit;
    }

    setUserEdit(data){
        this.useredit = data;
    }

    getUserEdit(){
        return this.useredit;
    }

    setloggedData(data){
        this.loggedData = data;
        // console.log(this.loggedData);
        this.loginService.setLoginData(this.loggedData.at_username, this.loggedData.at_password);
    }

    getloggedData(){
        return this.loggedData;
    }

    checkvalidity(tab){
        if(tab == "overview" || tab == "places" || tab == "report" || tab == "alerts" || tab == "settings" || tab == "control" || tab == "help" || tab == "password"){
            return true;
        }
        if(this.loggedData.role == "Superadmin"){
            if(tab == "devicesuser" || tab == "premises" || tab == "tariff"){
                return false;
            }
            return true;
        }else if(this.loggedData.role == "Admin"){
            if(tab == "company" || tab == "premises" || tab == "devices"){
                return false;
            }
            let tabsArr = this.loggedData.tab.split(',');
            for(let i=0;i<tabsArr.length;i++){
                if(tab == tabsArr[i]){
                    return true;
                }
            }
            if(tab == "devicesuser" || tab == "master" || tab == "users" || tab == "tariff") {
                return true;
            }
            return false;
        }else if(this.loggedData.role == "User"){
            if(tab == "master"){
                return false;
            }
            let tabsArr = this.loggedData.tab.split(',');
            for(let i=0;i<tabsArr.length;i++){
                if(tab == tabsArr[i]){
                    return true;
                }
            }
            if(tab == "devicesuser") {
                return true;
            }
            return false;
        }
        return false;
    }

    getColors(){
        let colorArr = ["rgb(126, 178, 109)","rgb(234, 184, 57)","rgb(110, 208, 224)","rgb(177, 198, 251)","rgb(240, 186, 196)","rgb(243, 233, 171)","rgb(91, 83, 137)","rgb(18, 156, 108)","rgb(137, 230, 108)","rgb(221, 156, 108)","rgb(137, 156, 108)","rgb(215, 104, 37)","rgb(251, 249, 37)","rgb(93, 100, 154)","rgb(242, 180, 129)","rgb(131, 128, 136)","rgb(249, 128, 136)","rgb(249, 52, 52)","rgb(52, 249, 150)","rgb(255, 200, 200)","rgb(249, 101, 52)","rgb(250, 82, 216)","rgb(250, 107, 222)","rgb(251, 131, 227)","rgb(252, 156, 233)","rgb(253, 181, 238)","rgb(253, 206, 244)","rgb(254, 230, 249)","rgb(124, 4, 100)"];
        return colorArr;
    }

    setGraphData(data){
        this.graphData = data;
    }

    getGraphData(){
        return this.graphData;
    }

    setMasterData(data){
        this.masterdata = data;
    }

    getMasterData(){
        return this.masterdata;
    }

    setDeviceData(data) {
        this.deviceData = data;
    }

    getDeviceData() {
        return this.deviceData;
    }

    setPlacePremise(data) {
        this.placePremise = data;
    }

    getPlacePremise() {
        return this.placePremise;
    }

    setPlaceCompany(data) {
        this.placeCompany = data;
    }

    getPlaceCompany() {
        return this.placeCompany;
    }

    setFilterType(data) {
        this.filterType = data;
    }

    getFilterType() {
        return this.filterType;
    }

    setFilterEnergyType(data) {
        this.filterEnergyType = data;
    }

    getFilterEnergyType() {
        return this.filterEnergyType;
    }

    async getCombinedData(parameter_data) {
        console.log("Started on ", Math.floor(Date.now() / 1000));
        let device_data: any[] = new Array();
        let counter: number = 0;
        for (let i=0; i<parameter_data.devices.length; i++) {
            let resp: any;
            try {
                const params = {uuid:parameter_data.devices[i],measurement:'power',range_start:parameter_data.range_start,range_end:parameter_data.range_end,type:parameter_data.type,aggregation:parameter_data.aggregation,timezone:'Asia/Kolkata'};
                resp = await this.httpService.getApi(params);

                if (!!resp && !!resp.data) {
                  for (let i=0; i<resp.data.length; i++) {
                    for (let j=1; j<resp.data[i].length; j++) {
                      if (resp.data[i][j] == null) {
                        resp.data[i][j] = 0;
                      }
                    }
                  }
                }            
            } catch { }
            
            if (!!resp && parameter_data.method == "sum") {
                // console.log(resp.data);
                if (counter == 0) {
                    for (let j=0; j<resp.data.length; j++) {
                        let data: any[] = new Array();
                        data.push(resp.data[j][0]);
                        data.push(resp.data[j][1]);
                        device_data.push(data)
                    }
                    counter++;
                } else {
                    for (let j=0; j<device_data.length; j++) {
                        // device_data[j].push(resp.data[j][1]);    // uncomment this line to just add values datapoints wise
                        device_data[j][1] += resp.data[j][1];
                    }
                }
            }
            else if(!!resp && parameter_data == "combine") {
                if (counter == 0) {
                    for (let j=0; j<resp.data.length; j++) {
                        let data: any[] = new Array();
                        data.push(resp.data[j][0]);
                        data.push(resp.data[j][1]);
                        device_data.push(data)
                    }
                    counter++;
                } else {
                    for (let j=0; j<resp.data.length; j++) {
                        device_data[j].push(resp.data[j][1]);    // uncomment this line to just add values datapoints wise
                        // device_data[j][1] += resp.data[j][1];
                    }
                }
            }
        }
        console.log("Completed on ", Math.floor(Date.now() / 1000));
        // console.log(device_data);
        return device_data;
    }

    public getCurrentDate(){
        let newdate = moment().format("MM/DD/YYYY");
        return newdate;
    }

    public getYLabel(type, splitted:boolean = false) {
        // console.log("method called...");
        let unit = {"p": "Power (W)", "em": "Energy (kWh)", "u": "Voltage (V)", "i": "Current (A)", "pf": "Power Factor", "p_appr": "Apparent Power (VA)", "rq": "Reactive Power (VAR)", "es": "Apparent Energy (kVAh)", "eq": "Reactive Energy (kVARh)", "eqn": "Negative Reactive Energy (-kVARh)", "hu": "Voltage Harmonics (V)", "hi": "Current Harmonics (A)", "thdi": "Current Total Harmonics Distortion (%)", "thdu": "Voltage Total Harmonics Distortion (%)", "yu": "Voltage Phase Angle (\u00B0)", "yi": "Current Phase Angle (\u00B0)"}
        if(!splitted) {
            if (type != "freq"){
                type = type.toString().split(",")[0].slice(0, type.toString().split(",")[0].length-1);
                return unit[type];
            } else {
                return "Frequency (Hz)";
            }
        } else {
            if (type != "freq" && type != "ec"){
                return unit[type];
            } else if (type == "freq") {
                return "Frequency (Hz)";
            } else if (type == "ec") {
                return "Energy Cost";
            }
        }
    }

    public unitConversion(num, unit?) {
        console.log("Unit consversion method called...");
        if (!unit) {
            unit = "kWh";
        }

        if (Math.floor(num/1000) > 0 && unit == "kWh") {
            unit = "MWh";
            num = num / 1000;
        }
        if (Math.floor(num/1000) > 0 && unit == "MWh") {
            unit = "GWh";
            num = num / 1000;
        }

        return num.toFixed(2) + " " + unit;
    }

    public dateAlignment(array){
        if(!!array && Array.isArray(array) && array.length>0){
            if(!!array[0].time){
                array.sort(function(a, b) {
                    let c:any = a.time;
                    let d:any = b.time;
                    return c-d;
                });
            }else if(!!array[0].time1){
                array.sort(function(a, b) {
                    let c:any = a.time1;
                    let d:any = b.time1;
                    return c-d;
                });
            }else if(!!array[0].time2){
                array.sort(function(a, b) {
                    let c:any = a.time2;
                    let d:any = b.time2;
                    return c-d;
                });
            }else if(!!array[0].time3){
                array.sort(function(a, b) {
                    let c:any = a.time3;
                    let d:any = b.time3;
                    return c-d;
                });
            }
        }
        return array;
    }

    setIsMobile(value){
        this.isMobile = value;
    }

    getIsMobile(){
        return this.isMobile;
    }
}