import { Component, ElementRef, Input, OnInit } from '@angular/core';
import * as d3 from 'd3';
import { CommonService } from 'src/app/common/services/commonservice.service';

@Component({
  selector: 'app-stacked',
  templateUrl: './stacked.component.html',
  styleUrls: ['./stacked.component.scss']
})
export class StackedComponent implements OnInit {
  @Input() data: any;

	private w: number = 1060;
	private h: number = 400;
	private margin = {top: 10, right: 50, bottom: 60, left: 100};
	private width = this.w - this.margin.left - this.margin.right;
	private height = this.h - this.margin.top - this.margin.bottom;

	private x: any;
	private y: any;
	private svg: any;
	private g: any;
	private stack: any;
	private chart: any;
	private layersBarArea: any;
	private layersBar: any;
	private xAxis: any;
	private yAxis: any;
	private legend: any;
	private legendItems: any;
	private tooltip: any;
	private stackedSeries: any;
	mode:any;
	private colors = ['#00D7D2', '#313c53', '#7BD500'];

	constructor(private container: ElementRef,public commonService:CommonService){

	}

	ngOnInit() {
		this.commonService.nightmode.subscribe(data => {
			if (!!data) {
				this.drawAxis('black');
			} else {
				this.drawAxis('white');
			}
		});

		this.mode = (localStorage.getItem('mode')=="true")?'black':'white';

		this.stack = d3.stack()
			.keys(['Rating','Rating1'])

		this.initScales();
		this.initSvg();
		this.createStack(this.data);
		this.drawAxis(this.mode);
	}

	private initScales(){
		this.x = d3.scaleBand()
			.rangeRound([0, this.width])
			.padding(0.05);

		this.y = d3.scaleLinear()
			.range([this.height, 0])
	}

	private initSvg() {

		this.svg = d3.select(this.container.nativeElement)
			.select('.chart-container')
			.append('svg')
			.attr("preserveAspectRatio", "xMinYMin meet")
			.attr('class', 'chart')
			.attr('width', this.w)
			.attr('height', this.h)
			.attr("viewBox", "0 0 600 400")

		this.chart = this.svg.append('g')
			.classed('chart-contents', true)
			.attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

		this.layersBarArea = this.chart.append('g')
			.classed('layers', true);
	}

	private drawAxis(color){

		this.xAxis = this.chart.append('g')
			.classed('x axis', true)
			.attr("transform", "translate(0," + this.height + ")")
			.call(d3.axisBottom(this.x));

		this.xAxis.selectAll("line")
			.style("stroke", color);
	
		this.xAxis.selectAll("path")
			.style("stroke", color);
	
		this.xAxis.selectAll("text")
			.style("stroke", color)
			.style("stroke-width", '0.3');

		this.chart.append("text")
			.attr("y", this.height + 40)
			.attr("x", (this.width/2))
			.classed('axis-title', true)
			.style("text-anchor", "middle")
			.style('stroke', color)
			.text("xTitle");

		this.yAxis = this.chart.append('g')
			.classed('y axis', true)
			.call(d3.axisLeft(this.y)
				.ticks(7));

		this.yAxis.selectAll("line")
			.style("stroke", color);
	
		this.yAxis.selectAll("path")
			.style("stroke", color);
	
		this.yAxis.selectAll("text")
			.style("stroke", color)
			.style("stroke-width", '0.3');

		this.chart.append("text")
			.attr("transform", "rotate(-90)")
			.attr("y", 0 - 60)
			.attr("x", 0 - (this.height / 2))
			.style("text-anchor", "middle")
			.style('stroke', color)
			.classed('axis-title', true)
			.text("yTitle");
	}

	private createStack(stackData:any){
		this.stackedSeries = this.stack(stackData);
			  console.log(this.stackedSeries)
		this.drawChart(this.stackedSeries)
	}

	private drawChart(data:any){
		this.layersBar = this.layersBarArea.selectAll('.layer')
			.data(data)
			.enter()
			.append('g')
			.classed('layer', true)
			.style('fill', (d:any,i:any)=>{
				return this.colors[i]
			});

		this.x.domain(this.data.map((d:any)=>{
			return d.Released
		}));
		
		this.y.domain([0, +d3.max(this.stackedSeries, function(d:any){
			return d3.max(d, (d:any)=>{
				return d[1]
			})
		})]);

		this.layersBar.selectAll('rect')
			.data((d:any)=>{
				return d;
			})
			.enter()
			.append('rect')
			.attr('y', (d:any)=>{
				return this.y(d[1])
			})
			.attr('x', (d:any, i:any)=>{
				return this.x(d.data.Released)
			})
			.attr('width', this.x.bandwidth())
			.attr('height', (d:any, i:any)=>{
				return this.y(d[0]) - this.y(d[1]);
			})
	}
}