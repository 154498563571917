import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { CommonService } from './commonservice.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
    constructor(public router: Router,private commonService:CommonService) {}
    canActivate(): boolean {
        // console.log("inside AuthGuardService");
        let loginData = this.commonService.getloggedData();
        if (!!loginData) {
            return true;
        }else{
            this.router.navigate(['login']);
            return false;
        }
    }
}