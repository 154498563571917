import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/login/loginService.service';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
    constructor(private loginService: LoginService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add header with basic auth credentials if user is logged in and request is to the api url
        const authdata = this.loginService.getLoginData();
        const isLoggedIn = this.loginService.isLogin;
        const token = this.loginService.token;
        // const isApiUrl = request.url.startsWith(environment.apiUrl);
        if (!!isLoggedIn) {
            // console.log("login interceptor");
            request = request.clone({
                setHeaders: { 
                    'Content-Type': "application/x-www-form-urlencoded",
                    'Authorization': "Basic " + btoa(authdata.Username + ":" + authdata.Password)
                }
            });
        }else{
            // console.log("data interceptor");
            request = request.clone({
                setHeaders: { 
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': "application/json"
                }
            });
        }

        return next.handle(request);
    }
}