import { NgModule } from "@angular/core";
import { BarComponent } from "./bar/bar.component";
import { LineComponent } from "./line/line.component";
import { PieComponent } from "./pie/pie.component";
import { StackedComponent } from "./stacked/stacked.component";
import { TreeComponent } from "./tree/tree.component";
import { DonutComponent } from './donut/donut.component';
import { CommonModule } from '@angular/common';
import { GaugeComponent } from './gauge/gauge.component';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { FiltersComponent } from "../filters/filters.component";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    imports: [CommonModule,NgMultiSelectDropDownModule,FormsModule,NgbModule],
    declarations: [
        PieComponent,
        LineComponent,
        BarComponent,
        StackedComponent,
        TreeComponent,
        DonutComponent,
        GaugeComponent,
        FiltersComponent],
    exports: [
        CommonModule,
        PieComponent,
        LineComponent,
        BarComponent,
        StackedComponent,
        TreeComponent,
        DonutComponent,
        GaugeComponent,
        FiltersComponent]
})
export class GraphsModule {}