import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ApiConstants } from '../constants/ApiConstants';
import { LoginService } from 'src/app/login/loginService.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    // baseUrl: String = 'http://65.0.26.163/phpapi';
    // baseUrl: String = 'https://redcherry.live/phpapi';
    // baseUrl: String = 'http://13.233.208.229/PHP_RC_DEVELOP';
    // baseUrl: String = 'http://redcherry.techbuild.online/phpapi';
    // baseUrl: String = 'http://localhost/redcherry/php';
    baseUrl: String = 'https://analytics.techbuild.online/apis';

    constructor(private http: HttpClient, private loginService: LoginService){}
    
    getCustomForecast() {
        return this.http.get(this.baseUrl + '/get_customForecast.php')
        .pipe(map(CustomForecast => {
            return CustomForecast;
        }));
    }
    
    emptyCustomForecast() {
        return this.http.post<any>(this.baseUrl + '/truncate_customForecast.php', { })
        .pipe(map(CustomForecast => {
            return CustomForecast;
        }));
    }
    
    addCustomForecast(sku: any, type: any, production: any, kwh: any) {
        return this.http.post<any>(this.baseUrl + '/add_customForecast.php', { sku, type, production, kwh })
        .pipe(map(CustomForecast => {
            return CustomForecast;
        }));
    }
    
    editCommentSeen(id: any, username: any) {
        return this.http.post<any>(this.baseUrl + '/edit_commentSeen.php', { id, username })
        .pipe(map(Comment => {
            return Comment;
        }));
    }
    
    addAlertComments(id: any, name: any, comments: any, username: any) {
        return this.http.post<any>(this.baseUrl + '/add_comments.php', { id, name, comments, username })
        .pipe(map(Comments => {
            return Comments;
        }));
    }
    
    getAlertComments(id: any, name: any) {
        return this.http.get(this.baseUrl + '/get_alertComments.php?id=' + id + '&name=' + name)
        .pipe(map(Comments => {
            return Comments;
        }));
    }
    
    deleteReport(id: any, name: any) {
        return this.http.post<any>(this.baseUrl + '/delete_report.php', { id, name })
        .pipe(map(Report => {
            return Report;
        }));
    }
    
    editSeen(id: any, name: any, company: any, premise: any, username: any) {
        return this.http.post<any>(this.baseUrl + '/edit_seen.php', { id, name, company, premise, username })
        .pipe(map(Alert => {
            return Alert;
        }));
        
    }
    
    getImage(company: any, premise: any) {
        return this.http.get(this.baseUrl + '/get_image.php?company=' + company + '&premise=' + premise)
        .pipe(map(Image => {
            return Image;
        }));
    }
    
    editImage(company: any, premise: any, image: String) {
        return this.http.post<any>(this.baseUrl + '/edit_image.php', { company, premise, image })
        .pipe(map(Company => {
            return Company;
        }));
    }
    
    getImageData() {
        return this.http.get(this.baseUrl + '/get_imageData.php')
        .pipe(map(ImageData => {
            return ImageData;
        }));
    }
    
    getReport(company: any) {
        return this.http.get(this.baseUrl + '/get_report.php?company=' + company)
        .pipe(map(Report => {
            return Report;
        }));
    }
    
    addReport(name: any, company: any, created_by: any, filter: any) {
        return this.http.post<any>(this.baseUrl + '/add_report.php', { name, company, created_by, filter })
        .pipe(map(Report => {
            return Report;
        }));
    }
    
    deleteAlert(id: any, name: any, device: any, limit: any, created_by: any) {
        return this.http.post<any>(this.baseUrl + '/delete_alert.php', { id, name, device, limit, created_by })
        .pipe(map(Alert => {
            return Alert;
        }));
    }
    
    addAlert(name: any, company: any, premise: any, uuid: any, device_name: any, phase: any, limit_type: any, limit: any, range_start: any, range_end: any, created_by: any) {
        return this.http.post<any>(this.baseUrl + '/add_alert.php', { name, company, premise, uuid, device_name, phase, limit_type, limit, range_start, range_end, created_by })
        .pipe(map(Alert => {
            return Alert;
        }));
    }
    
    addImage(company: any, premise: any, image: String) {
        return this.http.post<any>(this.baseUrl + '/add_image.php', { company, premise, image })
        .pipe(map(Company => {
            return Company;
        }));
    }
    
    getAlert() {
        return this.http.get(this.baseUrl + '/get_alert.php')
        .pipe(map(Alerts => {
            return Alerts;
        }));
    }
    
    getParticularTariff(company: any, premise: any) {
        let promise = new Promise((resolve,reject)=>{
            this.http.get(this.baseUrl + '/get_particularTariff.php?company=' + company + '&premise=' + premise).subscribe(
                data => {
                    resolve(data);
                },
                err => { 
                    resolve(err);
                }
            );
        });
        return promise;
    }
    
    deleteTariff(id: any, created_by: any, premise: any, energy_type: any) {
        return this.http.post<any>(this.baseUrl + '/delete_tariff.php', { id, created_by, premise, energy_type })
        .pipe(map(Tariff => {
            return Tariff;
        }));
    }
    
    getTariff() {
        return this.http.get(this.baseUrl + '/get_tariff.php')
        .pipe(map(Tariff => {
            return Tariff;
        }));
    }
    
    addTariff(username: any, company: any, premise: any, provider: any, currency: any, energy_type: any, peak_time: any, date: any, slabs: any, unit_type: any) {
        return this.http.post<any>(this.baseUrl + '/add_tariff.php', { username, company, premise, provider, currency, energy_type, peak_time, date, slabs, unit_type })
        .pipe(map(Tariff => {
            return Tariff;
        }));
    }

    editPlaceFilter(username: any, places_filter: String) {
        return this.http.post<any>(this.baseUrl + '/edit_placesFilter.php', { username, places_filter })
        .pipe(map(Customer => {
            return Customer;
        }));
    }
    
    editPassword(username: any, newpass: any) {
        return this.http.post<any>(this.baseUrl + '/edit_password.php', { username, newpass })
        .pipe(map(Customer => {
            return Customer;
        }));
    }

    getParticularCompany(name: any) {
        // return this.http.get('http://localhost/CustomVisualizatioProject_v2/php/get_ParticularCompany.php?name=' + name )
        return this.http.get(this.baseUrl + '/get_particularCompany.php?name=' + name )
        .pipe(map(Company => {
            return Company;
        }));
    }
    
    deleteCustomer(name: any) {
        return this.http.post<any>(this.baseUrl + '/delete_customer.php', { name })
        .pipe(map(Customer => {
            return Customer;
        }));
    }
    
    editCustomer(name: any, name_og: any, username: any, email: any, password: any, role: String, loginUser: any, number: any, at_username: any, at_password: any, company: any, premise: string, tabs: string, terminationDate: any) {
        return this.http.post<any>(this.baseUrl + '/edit_customer.php', { name, name_og, username, email, password, role, loginUser, number, at_username, at_password, company, premise, tabs, terminationDate })
        .pipe(map(Customer => {
            return Customer;
        }));
    }
    
    deleteCompany(companyName: any) {
        return this.http.post<any>(this.baseUrl + '/delete_company.php', { companyName })
        .pipe(map(Company => {
            return Company;
        }));
    }
    
    getParticularUsers(adminname: any) {
        return this.http.get(this.baseUrl + '/get_particularUsers.php?adminname=' + adminname )
        .pipe(map(Users => {
            return Users;
        }));
    }
    
    getUserCompanyNames(username: any) {
        return this.http.get(this.baseUrl + '/get_userCompany.php?username=' + username )
        .pipe(map(Company => {
            return Company;
        }));
    }
    
    getUsers() {
        return this.http.get(this.baseUrl + '/get_users.php')
        .pipe(map(Users => {
            return Users;
        }));
    }
    
    addCustomer(name: any, username: any, email: any, password: any, role: String, loginUser: any, number: any, at_username: any, at_password: any, company: any, premise: any, tabs: any, terminationDate: any) {
        return this.http.post<any>(this.baseUrl + '/add_customer.php', { name, username, email, password, role, loginUser, number, at_username, at_password, company, premise, tabs, terminationDate })
        .pipe(map(Company => {
            return Company;
        }));
    }
    
    getRole(username: any) {
        return this.http.get(this.baseUrl + '/get_role.php?username=' + username )
        .pipe(map(Role => {
            return Role;
        }));
    }

    editCompany(companyName: any, companyNameOG: any) {
      return this.http.post<any>(this.baseUrl + '/edit_company.php', { companyName, companyNameOG })
      .pipe(map(Company => {
          return Company;
      }));
    }
    
    addDevice(companyName: any, deviceId: String, deviceName: String, deviceCategory: String, devicePhase: String, deviceRatio: String, deviceDiameter1: String, deviceDiameter2: String) {
        return this.http.post<any>(this.baseUrl + '/add_device.php', { companyName, deviceId, deviceName, deviceCategory, devicePhase, deviceRatio, deviceDiameter1, deviceDiameter2 })
        .pipe(map(Devices => {
            return Devices;
        }));
    }
    
    getCompanyNames() {
        return this.http.get(this.baseUrl + '/company.php')
        .pipe(map(Devices => {
            return Devices;
        }));
    }
    
    getAllDevices() {
        return this.http.get(this.baseUrl + '/get_all_devices.php')
        .pipe(map(Devices => {
            return Devices;
        }));
    }
    
    editPremise(company: any, premise: any, floor: any, city: any, state: any, country: any, location: any, device_id: any, device_name: any, device_category: any, device_phase: any, device_ct_ratio: any, device_ct_diameter1: any, device_ct_diameter2: any) {
        return this.http.post<any>(this.baseUrl + '/edit_premise.php', { company, premise, floor, city, state, country, location, device_id, device_name, device_category, device_phase, device_ct_ratio, device_ct_diameter1, device_ct_diameter2 })
        .pipe(map(Premises => {
            return Premises;
        }));
    }

    deletePremise(company: any, premise: any, premise_image: any, floor: any, city: any, state: any, country: any, location: any, device_id: any, device_name: any, device_category: any, device_phase: any, device_ct_ratio: any, device_ct_diameter1: any, device_ct_diameter2: any) {
        return this.http.post<any>(this.baseUrl + '/delete_premise.php', { company, premise, premise_image, floor, city, state, country, location, device_id, device_name, device_category, device_phase, device_ct_ratio, device_ct_diameter1, device_ct_diameter2 })
        .pipe(map(Premises => {
            return Premises;
        }));
    }

    addPremise(company: any, premise: any, floor: any, city: any, state: any, country: any, location: any) {
        return this.http.post<any>(this.baseUrl + '/add_premises.php', { company, premise, floor, city, state, country, location })
        .pipe(map(Premises => {
            return Premises;
        }));
    }

    getPremises(companyName: string) {        
        return this.http.get(this.baseUrl + '/get_premises.php?companyName=' + companyName)
        .pipe(map(Premises => {
            return Premises;
        }));
    }

    addCompany(companyName: any) {
        return this.http.post<any>(this.baseUrl + '/add_company.php', { companyName })
        .pipe(map(Premises => {
            return Premises;
        }));
    }

    public getCompany(){
        return this.http.get(this.baseUrl + '/company.php')
        .pipe(map(Company => {
            return Company;
        }));
    }

    loginApi(){
        let self = this;

        let promise = new Promise((resolve,reject)=>{ 

            self.loginService.setToken(null,true); //for interceptor
            let url = ApiConstants.loginURL;

            let headers = new HttpHeaders();
            headers.append("Content-Type", "application/x-www-form-urlencoded");

            self.http.post(url,{headers:headers}).subscribe(
                data => {
                    self.loginService.setToken(data["access_token"],false);
                    resolve(true);
                },
                err => {
                    resolve(false);
                }
            );
        });
        return promise;
    }

    getApi(params,api?){
        let self = this;
        let promise = new Promise((resolve,reject)=>{ 

            let url = ApiConstants.URL;

            let headers = new HttpHeaders();
            // headers.append("Content-Type", "application/json");

            self.http.get(url,{params:params}).subscribe(
                data => { 
                    resolve(data);
                },
                err => {
                    resolve(err);
                }
            );
        });
        return promise;
    }
}