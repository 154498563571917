import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { CommonService } from '../common/services/commonservice.service';
import { HttpService } from '../common/services/httpservice.service';

@Injectable({
    providedIn: 'root' 
})
export class OverviewService {
    tariffData: any;
    selectedTariff: any;
    tariffIndex: any;
    constructor(private httpService: HttpService, private commonService: CommonService){}

    public async multipleDevicesData(array){
        console.log("overview service multipleDevicesData called...");
        let maindata = [];
        
        for(let i=0;i<array.length;i++){
            const params = array[i];
            let devicename = JSON.parse(JSON.stringify(params.devicename));
            delete params.devicename;
            const resp:any = await this.httpService.getApi(params);

            if(!resp.type){
                console.log("params for failed case "+params);
                continue;
            }

            if (!!resp && !!resp.data) {
                for (let i=0; i<resp.data.length; i++) {
                    for (let j=1; j<resp.data[i].length; j++) {
                        if (resp.data[i][j] == null) {
                            resp.data[i][j] = 0;
                        }
                    }
                }
            }

            let labelLength = resp.type.split(',');
            if(i == 0){
                for(let j=0;j<resp.data.length;j++){
                    let obj:any = {};

                    obj["unix"] = resp.data[j][0];
                    const date = new Date(resp.data[j][0]*1000);
                    obj["time"] = date.getDate();
                    let yseries = devicename.split(' ').join('');
                    obj[yseries] = resp.data[j][1] + ((labelLength.length>1)?resp.data[j][2]:0) +  ((labelLength.length>2)?resp.data[j][3]:0);
                    maindata.push(obj);
                }
            }else{
                for(let k=0;k<resp.data.length;k++){
                    let obj:any = {};
                    
                    obj["unix"] = resp.data[k][0];
                    const date = new Date(resp.data[k][0]*1000);
                    let yseries = devicename.split(' ').join('');
                   
                    let index = maindata.findIndex(obj =>  moment(obj.time).isSame(date.getDate()));
                    if(index >= 0){    
                        maindata[index][yseries] = ((!!maindata[index][yseries]?maindata[index][yseries]:0) + resp.data[k][1]) + ((labelLength.length>1)?resp.data[k][2]:0) +  ((labelLength.length>2)?resp.data[k][3]:0);
                    }else{
                        obj["time"] = date.getDate();
                        obj[yseries] = resp.data[k][1] + ((labelLength.length>1)?resp.data[k][2]:0) +  ((labelLength.length>2)?resp.data[k][3]:0);
                        maindata.push(obj);
                    }
                }
            }
        }
        return maindata;
    }

    public async multipleDeviceData(filters,mainObj){
        let mulData:any={obj1:"",obj2:""};
        let params = this.getParamsForDevice(filters,mainObj);
        
        mulData.obj1 = await this.multipleDevicesData(params);
        return mulData;
    }
    
    public getParamsForDevice(filters,mainObj){
        let array = [];
        for(let j=0;j<mainObj.obj1.device.length;j++){ //for places and premises
            let type = this.formType(filters,mainObj.obj1.phase[j]);
            array.push({devicename:mainObj.obj1.devicename[j],uuid:mainObj.obj1.device[j],measurement:filters.measurement,range_start:filters.range_start,range_end:filters.range_end,type:type,aggregation:filters.aggregation,timezone:filters.timezone});
        }
        return array;
    }

    public formType(filters,phase){
        let type;
        if (filters.type == "freq") {
            type = "freq";
        }
        else if(phase == '1st'){
            if (filters.type != "ec") {
                type = filters.type + '1';
            } else {
                type = "em1";
            }
        }else if(phase == '2nd'){
            if (filters.type != "ec") {
                type = filters.type + '2';
            } else {
                type = "em2";
            }
        }else if(phase == '3rd'){
            if (filters.type != "ec") {
                type = filters.type + '3';
            } else {
                type = "em3";
            }
        }else if(phase == '1+2+3'){
            for(let i=0;i<3;i++){
                if(i == 0){
                    if (filters.type != "ec") {
                        type = filters.type + (i+1);
                    } else {
                        type = "em" + (i+1);
                    }
                }else{
                    if (filters.type != "ec") {
                        type = type + "," + filters.type + (i+1);
                    } else {
                        type = type + ",em" + (i+1);
                    }
                }
            }
        }
        return type;
    }

}