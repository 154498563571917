import { Component, OnInit, ElementRef, Input, ViewChild} from '@angular/core';
import * as d3 from 'd3';
import { CommonService } from 'src/app/common/services/commonservice.service';

@Component({
  selector: 'app-line',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.scss']
})
export class LineComponent implements OnInit {

  @Input("data") public data: LineChartModel[] = [];
  @Input("xLabels") public xLabels: string[] = [];
  @Input("showDots") private showDots: boolean = false;
  @Input("dateFormat") format = "%Y";
  private dataAdaptedToD3Js = [];
  public svg;
  public domainMaxRange: number = 0;
  public domainMinRange: number = 0;
  public dateFormat: Function;
  // set the dimensions and margins of the graph
  margin = { top: 12, right: 100, bottom: 30, left: 30 };
  width = 1060 - this.margin.left - this.margin.right;
  height = 400 - this.margin.top - this.margin.bottom;
  constructor(public chartElem: ElementRef,public commonService:CommonService) {
    this.dateFormat = d3.timeFormat(this.format);
  }

  ngOnInit(){
    // d3.select("svg").remove();
    this.commonService.nightmode.subscribe(data => {
    this.domainMinRange =0;
      if (!!data) {
        this.draw('black');
      } else {
        this.draw('white');
      }
    });

      let mode = (localStorage.getItem('mode')=="true")?'black':'white';
      this.draw(mode);
    
  }

  covertToDate(array: any[]) {
    array = array.map(element => {
      let date = new Date(element);
      return date;
    });
    return array;
  }

  draw(color) {
    if (this.data.length < 10) {
      this.domainMinRange = 0;
      this.domainMaxRange = 0;
      this.dataAdaptedToD3Js = [];

      this.xLabels = this.covertToDate(this.xLabels);

      this.data[0].data.forEach((element, index) => {
        let objectAdapted = { label: this.xLabels[index] };
        for (let i = 0; i < this.data.length; i++) {
          objectAdapted[this.data[i].label] = this.data[i].data[index];
          if (this.domainMaxRange < this.data[i].data[index]) {
            this.domainMaxRange = this.data[i].data[index];
          }
          if (this.domainMinRange > this.data[i].data[index]) {
            this.domainMinRange = this.data[i].data[index];
          }
        }
        this.dataAdaptedToD3Js.push(objectAdapted);
      });
    }
    this.svg = d3
        .select("#my_dataviz")
        .select("svg").remove();
        // append the svg object to the body of the page
        this.svg = d3
        .select("#my_dataviz")
        .append("svg")
        //.attr("width", width + margin.left + margin.right)
        //.attr("height", height + margin.top + margin.bottom)
        .attr(
          "viewBox",
          `0 0 ${this.width + this.margin.left + this.margin.right} ${this.height +
            this.margin.top +
            this.margin.bottom}`
        )
        .append("g")
        .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

    // A color scale: one color for each group
    let myColor = {};
    // List of groups (here I have one group per column)
    let allGroup = [];
    for (let i = 0; i < this.data.length; i++) {
      allGroup.push(this.data[i].label);
      myColor[this.data[i].label] = this.data[i].color;
    }

    // Reformat the data: we need an array of arrays of {x, y} tuples
    let dataReady = allGroup.map(grpName => {
      this.dataAdaptedToD3Js.forEach(d => {
        return { label: this.dateFormat(d.label), value: +d[grpName] };
      });
      // .map allows to do something for each element of the list
      return {
        name: grpName,
        values: this.dataAdaptedToD3Js.map(d => {
          return { label: this.dateFormat(d.label), value: +d[grpName] };
        })
      };
    });

    // I strongly advise to have a look to dataReady with
    // console.log(dataReady)

    // Add X axis --> it is a date format
    let x = d3
      .scaleTime()
      .domain(
        d3.extent(this.dataAdaptedToD3Js, d => {
          return this.dateFormat(d.label);
        })
      )
      .rangeRound([0, this.width]);

    const xaxis = d3.axisBottom(x).tickFormat(d3.format("0"));
    //.scale(x);

    this.svg
      .append("g")
      .attr("transform", "translate(0," + this.height + ")")
      .call(xaxis);

    this.svg = this.drawAxis(color);

    // Add Y axis
    this.domainMinRange =
      this.domainMinRange == 0 ? 0 : this.domainMinRange + -5;

    let y = d3
      .scaleLinear()
      .domain([
        this.domainMinRange,
        Math.abs(
          this.domainMaxRange + (this.domainMaxRange * 0, 2) + this.data.length
        )
      ])
      .range([this.height, 0]);
    this.svg.append("g").call(d3.axisLeft(y));

    this.svg = this.drawAxis(color);

    // Add the lines
    let line = d3
      .line()
      .x(d => {
        return x(d["label"]);
      })
      .y(function(d) {
        return y(+d["value"]);
      });
    this.svg
      .selectAll("myLines")
      .data(dataReady)
      .enter()
      .append("path")
      .attr("class", d => {
        return d.name;
      })
      .attr("d", (d: any) => {
        return line(d.values);
      })
      .attr("stroke", d => {
        return myColor[d.name];
      })
      .style("stroke-width", 2)
      .style("fill", "none");

    // create a tooltip
    let Tooltip = d3
      .select("#my_dataviz")
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "2px")
      .style("border-radius", "5px")
      .style("padding", "5px");
    if (this.showDots) {
      this.svg
        // First we need to enter in a group
        .selectAll("myDots")
        .data(dataReady)
        .enter()
        .append("g")
        .style("fill", d => {
          return myColor[d.name];
        })
        .attr("class", d => {
          return d.name;
        })
        // Second we need to enter in the 'values' part of this group
        .selectAll("myPoints")
        .data(dataReady)
        .data(d => {
          return d.values;
        })
        .attr("class", "myCircle")
        .enter()
        .append("circle")
        .attr("cx", d => {
          return x(d.label);
        })
        .attr("cy", d => {
          return y(d.value);
        })
        .attr("r", 3)
        .attr("stroke", "white");
      //.on("mousemove", d => {
      // console.log("achraf", d.label);
      // });
    }

    this.svg
      .selectAll("myLabels")
      .data(dataReady)
      .enter()
      .append("g")
      .append("text")
      .attr("class", d => {
        return d.name;
      })
      .datum(d => {
        return { name: d.name, value: d.values[d.values.length - 1] };
      }) // keep only the last value of each time series
      .attr("transform", d => {
        return "translate(" + x(d.value.label) + "," + y(d.value.value) + ")";
      }) // Put the text at the position of the last point
      .attr("x", 12) // shift the text a bit more right
      .style("fill", d => {
        return myColor[d.name];
      })
      .style("font-size", 15);

    // Add a legend (interactive)
    let cy = 30;
    let ty = 33;
    this.data.forEach(element => {
      this.svg
        .append("circle")
        .attr("cx", 30)
        .attr("cy", cy)
        .style("cursor", "pointer")
        .attr("r", 6)
        .style("fill", element.color)
        .on("click", d => {
          let currentOpacity: any = d3
            .selectAll("." + element.label)
            .style("opacity");
          d3.selectAll("." + element.label).style(
            "opacity",
            currentOpacity == 1 ? 0 : 1
          );
        });
      this.svg
        .append("text")
        .attr("x", 40)
        .attr("y", ty)
        .text(element.legend)
        .style("font-size", "16px")
        .style("cursor", "pointer")
        .style("fill", element.color)
        .attr("alignment-baseline", "middle")
        .on("click", d => {
          let currentOpacity: any = d3
            .selectAll("." + element.label)
            .style("opacity");
          d3.selectAll("." + element.label).style(
            "opacity",
            currentOpacity == 1 ? 0 : 1
          );
        });
      cy = cy + 20;
      ty = ty + 20;
    });
    d3.selectAll(".tick text").style("font-size", "14px");
  }

  public drawAxis(color){
    this.svg.selectAll("line")
    .style("stroke", color);

    this.svg.selectAll("path")
      .style("stroke", color);

    this.svg.selectAll("text")
      .style("stroke", color)
      .style("stroke-width", '0.3');
    return this.svg;
  }
}
interface LineChartModel {
  label: string;
  data: any[];
  color: string;
  legend: string;
}