import { Component, OnInit, ViewEncapsulation, } from '@angular/core';
import { Color, Label, ThemeService } from 'ng2-charts';
import { CommonService } from '../common/services/commonservice.service';
import * as d3 from 'd3';
import { ChartType, RadialChartOptions } from 'chart.js';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Router } from '@angular/router';
import { HttpService } from '../common/services/httpservice.service';
import * as moment from 'moment';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { AppDataService } from '../common/services/appdataservice.service';
import { first } from 'rxjs/operators';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { OverviewService } from './overview.service';

@Component({
    selector: 'app-overview',
    templateUrl: './overview.page.html',
    styleUrls: ['./overview.page.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [ThemeService]
})
export class OverviewComponent implements OnInit{
  
  title = 'metricsVisual';
  filterData:any = [];
  data1=[
    {"Framework": "A.C", "Stars": "166443", "Released": "01-01-2014", "Rating": 100,"Rating1": 100},
    {"Framework": "TV", "Stars": "150793", "Released": "02-02-2013", "Rating": 30,"Rating1": 50},
    {"Framework": "Washing Machine", "Stars": "62342", "Released": "03-03-2016", "Rating": 50,"Rating1": 300},
    {"Framework": "Water Heater", "Stars": "27647", "Released": "04-04-2010", "Rating": 40,"Rating1": 40},
    {"Framework": "Tubelight", "Stars": "21471", "Released": "05-05-2011", "Rating": 10,"Rating1": 30}
  ];
  data2=[];

  //-------------------------------guage chart---------------------------
  gaugemap:any = {};
  canvas: any;
  ctx: any;
  resp:any;
  company: any;
  //-------------------------------guage chart---------------------------

  //-------------------------------line chart---------------------------
  lineChartData: ChartDataSets[];
  lineChartLabels: Label[];
  lineChartOptions: (ChartOptions);
  lineChartColors:  Color[];
  lineChartLegend: boolean;
  lineChartType: string;
  lineChartPlugins: any[];
  //-------------------------------line chart---------------------------

  //-------------------------------Doughnut-------------------------------
  doughnutChartOptions: ChartOptions;
  doughnutChartLabels: Label[];
  doughnutChartType: ChartType;
  doughnutChartLegend;
  doughnutChartData: ChartDataSets[];
  //-------------------------------Doughnut-------------------------------

  //-------------------------------Bar-------------------------------
  barChartOptions: ChartOptions;
  barChartLabels: Label[];
  barChartType: ChartType;
  barChartLegend;
  barChartData: ChartDataSets[];
  barChartColors: Color[];
  //-------------------------------Bar-------------------------------

  //-------------------------------Horizontal Bar-------------------------------
  horBarChartOptions: ChartOptions;
  horBarChartType: ChartType;
  horBarChartLegend;
  horBarChartData: ChartDataSets[];
  horBarChartLabels: string[];
  horBarChartColors: Color[];
  //-------------------------------Horizontal Bar-------------------------------

  //-------------------------------Radar-------------------------------
  radarChartOptions: RadialChartOptions;
  radarChartLabels: Label[];
  radarChartData: ChartDataSets[];
  radarChartType: ChartType;
  //-------------------------------Radar-------------------------------

  //-------------------------------table-------------------------------
  tablebarOptions: ChartOptions;
  tablebarLabels: Label[];
  tablebarType: ChartType;
  tablebarLegend;
  tablebarPlugins = [];
  tablebarData: ChartDataSets[];
  tablebarColors: Color[];
  //-------------------------------table-------------------------------

  //-------------------------------Stepped line chart---------------------------
  steppedLineChartData1: ChartDataSets[];
  steppedLineChartLabels1: Label[];
  steppedLineChartOptions1: (ChartOptions);
  steppedLineChartColors1:  Color[];
  steppedLineChartLegend1: boolean;
  steppedLineChartType1: string;
  steppedLineChartPlugins1: any[];

  steppedLineChartData2: ChartDataSets[];
  steppedLineChartLabels2: Label[];
  steppedLineChartOptions2: (ChartOptions);
  steppedLineChartColors2:  Color[];
  steppedLineChartLegend2: boolean;
  steppedLineChartType2: string;
  steppedLineChartPlugins2: any[];

  steppedLineChartData3: ChartDataSets[];
  steppedLineChartLabels3: Label[];
  steppedLineChartOptions3: (ChartOptions);
  steppedLineChartColors3:  Color[];
  steppedLineChartLegend3: boolean;
  steppedLineChartType3: string;
  steppedLineChartPlugins3: any[];

  steppedLineChartData4: ChartDataSets[];
  steppedLineChartLabels4: Label[];
  steppedLineChartOptions4: (ChartOptions);
  steppedLineChartColors4:  Color[];
  steppedLineChartLegend4: boolean;
  steppedLineChartType4: string;
  steppedLineChartPlugins4: any[];
  //-------------------------------Stepped line chart---------------------------

  //--------------------------------Maps----------------------------------------
  // google maps zoom level
  zoom: number = 8;
  markers:any;
  // initial center position for the map
  lat: number = 51.673858;
  lng: number = 7.815982;
  //--------------------------------Maps----------------------------------------

  allowedDevices: any[];
  allowedDeviceNames: any[];
  realtimeSec: any[];
  dayHourly: any[];
  monthDaily: any[];
  yearMonthly: any[];
  monthHourly: any[];

  graph1Total: any;
  graph2Total: any;
  graph3Total: any;
  graph4Total: any;

  premiseWise: any[];
  allowedPremise: any[];
  premiseConsumption: any[];
  alertPastOcc: number;
  lastAlert: any;
  seenAlertPastOcc: any;

  solarTotal: any = 0;
  dieselTotal: any = 0;
  gridTotal: any;
  grandTotal: any;
  exportTotal: any = 0;
  activeAlerts: number = 0;
  inactiveAlerts: number = 0;
  loginData: any;
  companyArr = [{code:"",value:""}];
  companies: any;
  dropdownSettings:IDropdownSettings = {singleSelection: true,idField: 'code',textField: 'value',itemsShowLimit: 1,maxHeight:160,allowSearchFilter: true,closeDropDownOnSelection: true};
  isCompanySelected: boolean = false;
  selectedCompany: any;
  selectedIndex: any = 0;

  mainObj: any = {obj1: {place:"", premise: "", device: [], phase: [], devicename:[]}, obj2: {place:"", premise: "", device: [], phase: [], devicename:[]}}
  isMobile = false;
  labelArr: any[];

  constructor(public overviewService:OverviewService,public appDataService:AppDataService,public commonService:CommonService,public route:Router,private httpService:HttpService){}

  ngOnInit(){
    console.log("overview page called");
    this.loginData = this.commonService.getloggedData();
    this.isMobile = this.commonService.getIsMobile();
    // let filters = {uuid:'rc000010',measurement:'power',range_start:'1605958440',range_end:'1605958840',type:'u1,u2,u3',aggregation:''};
    // this.appDataService.multipleData('Device',filters,["rc000010","0CEFAFCC8C05"],false);

    this.companies = this.commonService.getCompanyData();

    if (this.loginData.role == "Superadmin"){

      this.companyArr = new Array();
      for (let i=0; i<eval(this.companies).length; i++) {
        this.companyArr.push({
          code: eval(this.companies)[i].name,
          value: eval(this.companies)[i].name
        });
      }
    } else {

      let num: number = 0;
      this.allowedPremise = new Array();
      this.premiseWise = new Array();
      this.allowedDevices = new Array();
      this.allowedDeviceNames = new Array();
      for (let i=0; i<eval(this.commonService.getCompanyData()[0].premises).length; i++) {
        if (eval(this.commonService.getloggedData().premises).includes(eval(this.commonService.getCompanyData()[0].premises)[i])) {
          this.allowedPremise.push(eval(this.commonService.getCompanyData()[0].premises)[i]);
          let Arr = new Array();
          for (let j=0; j<eval(eval(this.commonService.getCompanyData()[0].floor)[i]).length; j++) {
            for (let k=0; k<eval(eval(eval(this.commonService.getCompanyData()[0].device_id)[i])[j]).length; k++) {
              Arr.push(num++);
              this.allowedDevices.push(eval(eval(eval(this.commonService.getCompanyData()[0].device_id)[i])[j])[k]);
              this.allowedDeviceNames.push(eval(eval(eval(this.commonService.getCompanyData()[0].device_name)[i])[j])[k]);
              this.mainObj.obj1.device.push(eval(eval(eval(this.commonService.getCompanyData()[0].device_id)[i])[j])[k]);
              this.mainObj.obj1.phase.push(eval(eval(eval(this.commonService.getCompanyData()[0].device_phase)[i])[j])[k]);
              this.mainObj.obj1.devicename.push(eval(eval(eval(this.commonService.getCompanyData()[0].device_name)[i])[j])[k]);
            }
          }
          this.premiseWise.push(Arr);
        }
      }
      // console.log(this.premiseWise);
      console.log(this.allowedDevices);
      console.log(this.mainObj);

      this.filterData = this.data1;
      this.commonService.tabSize.subscribe(data => {
        console.log("tab size changed "+data);
      });
      this.drawGraphs();
    }
  }

  public async drawGraphs(){
    await this.drawSteppedLine();
    await this.drawLine();

    try {
      this.drawDonut();
      this.drawHorBar();
      this.drawBar();
      // this.drawRadar();
    } catch { }

    try {
      this.drawMaps();
      this.loadAlertData();
    } catch { }

    try {
      this.loadImageData();
    } catch {
      this.gridTotal = '0 kWh';
      this.grandTotal = '0 kWh';
    }

    try {
      this.loadSubmetering();
    } catch {
      this.data2 = new Array();
      for (let i=0; i<this.allowedPremise.length; i++) {
        this.data2.push({
          name: this.allowedPremise[i],
          value: '0 kWh'
        })
      }
    }
  }

  public loadImageData() {
    this.gridTotal = this.graph3Total;
    // this.gridTotal = this.commonService.unitConversion(this.gridTotal);
    // for (let i=0; i<this.premiseConsumption.length; i++) {
    //   this.gridTotal += parseFloat(this.premiseConsumption[i].toFixed(2));
    // }

    this.grandTotal = parseFloat(this.solarTotal + this.dieselTotal + this.gridTotal);
    this.grandTotal = this.commonService.unitConversion(this.grandTotal,this.graph3Total.slice(-3));
  }

  public loadAlertData() {
    var now = new Date();
    let current_timestamp = Math.floor(Date.now() / 1000);
    let month: any = new Date(now.getFullYear(), now.getMonth());
    let month_timestamp = Math.round(month / 1000);

    this.alertPastOcc = 0;
    this.seenAlertPastOcc = 0;
    this.lastAlert = 0;
    this.activeAlerts = 0;
    this.inactiveAlerts = 0;
    this.httpService.getAlert()
    .pipe(first())
    .subscribe(
      data => {
        let alerts: any = data;
        for (let i=0; i<alerts.length; i++) {
          if (this.commonService.getloggedData().role != "Superadmin") {
            if (this.commonService.getloggedData().company == alerts[i].company && this.commonService.getloggedData().premises.includes(alerts[i].premise)) {
              if (this.lastAlert < alerts[i].log) {
                this.lastAlert = alerts[i].log;
              }

              if (alerts[i].log > month_timestamp && alerts[i].log < current_timestamp) {
                // console.log(alerts[i]);
                this.alertPastOcc++;

                if (!!alerts[i].seen) {
                  if (alerts[i].seen.split(',').includes(this.commonService.getloggedData().username)) {
                    this.seenAlertPastOcc++;
                  }
                }
              }

              if (alerts[i].log == "" || alerts[i].log == null) {
                this.activeAlerts++;
              } else {
                this.inactiveAlerts++;
              }
            }
          } else {
            if (this.selectedCompany == alerts[i].company) { 
              if (this.lastAlert < alerts[i].log) {
                this.lastAlert = alerts[i].log;
              }

              if (alerts[i].log > month_timestamp && alerts[i].log < current_timestamp) {
                // console.log(alerts[i]);
                this.alertPastOcc++;

                if (alerts[i].seen == "true") {
                  this.seenAlertPastOcc++;
                }
              }

              if (alerts[i].log == "" || alerts[i].log == null) {
                this.activeAlerts++;
              } else {
                this.inactiveAlerts++;
              }
            }
          }
        }

        if (this.lastAlert != 0) {
          this.lastAlert = new Date((parseInt(this.lastAlert) + 19800) * 1000).toISOString().slice(11, 19) + ", " + new Date((parseInt(this.lastAlert) + 19800) * 1000).toISOString().slice(0, 10);
        } else {
          this.lastAlert = "No Alerts";
        }

        // console.log(this.alertPastOcc, this.seenAlertPastOcc);
        this.seenAlertPastOcc = ((this.seenAlertPastOcc / this.alertPastOcc) * 100).toFixed(2);
      },
      error => {
        alert("Error");
      }
    );
  }

  public loadSubmetering() {
    // console.log(this.appDataService.rawData);

    this.premiseConsumption = new Array();
    for (let i=0; i<this.premiseWise.length; i++) {
      let sum: number = 0;
      for (let j=0; j<this.premiseWise[i].length; j++) {
        // console.log(this.premiseWise[i][j]);
        // console.log(this.appDataService.rawData[this.premiseWise[i][j]]);
        try {
          for (let k=0; k<this.appDataService.rawData[this.premiseWise[i][j]].length; k++) {
            for (let m=1; m<this.appDataService.rawData[this.premiseWise[i][j]][k].length; m++) {
              sum += this.appDataService.rawData[this.premiseWise[i][j]][k][m]
            }
          }
        } catch {}
      }
      this.premiseConsumption.push(sum);
    }
    // console.log(premiseConsumption);

    this.data2 = new Array();
    for (let i=0; i<this.allowedPremise.length; i++) {
      this.data2.push({
        name: this.allowedPremise[i],
        value: this.commonService.unitConversion(this.premiseConsumption[i])
      })
    }
  }

  public async drawLine(){
    var now = new Date();
    let current_timestamp = Math.floor(Date.now() / 1000);
    let month: any = new Date(now.getFullYear(), now.getMonth());
    let month_timestamp = Math.round(month / 1000);

    try {
      let resp:any;
      if(!this.appDataService.dashboardData.graph5 || this.commonService.getloggedData().role == "Superadmin"){ 
        console.log("API called for graph 5");
        const params = {uuid:'rc000010',measurement:'power',range_start:month_timestamp,range_end:current_timestamp,type:'em',aggregation:'hourly',timezone:'Asia/Kolkata',single:true};
        resp = await this.appDataService.multipleData('Device',params,this.mainObj,true);
        this.appDataService.dashboardData.graph5 = resp;
      }else{
        console.log("No API called for graph 5");
        resp = this.appDataService.dashboardData.graph5;
      }

      this.resp = resp.data;
      this.lineChartLabels = [];
      const dataArr = []; 

      if(!!resp.obj1 && resp.obj1.length>0){
        for(let i=0;i<resp.obj1.length;i++){
          const date = resp.obj1[i].time1;
          let d1 = moment(date).format("MMMM DD, YYYY HH:mm:ss");
          this.lineChartLabels.push(d1);
          dataArr.push(resp.obj1[i].value1);
        }
        this.lineChartData = [{ data: dataArr, label: 'Monthly',borderWidth: 3,pointBorderWidth: 1,pointRadius:1,pointHoverBorderColor: 'pink',pointHoverBorderWidth: 10,pointHoverRadius: 10 }];
        this.lineChartOptions = {
          responsive: true,
          tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              console.log(data);
            let label = data.datasets[0].label;
            let count: any = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            count = parseFloat(count).toFixed(2);
            return label + " " + count;
            },
          },
          },
          maintainAspectRatio: false,
          animation: {
            duration: 5000,
          },
          scales: {
            xAxes: [{
              type: 'time',
              time: {
                unit: 'day',
              },
            }],
          }
        };
        this.lineChartColors = [
          {
            borderColor: 'black',
            backgroundColor: 'rgba(255,0,0,0.3)',
          },
        ];
        this.lineChartLegend = true;
        this.lineChartType = 'line';
        this.lineChartPlugins = [];
      }else{
        this.lineChartData = null;
      }
    } catch { }
  }

  public async drawDonut(){
    let obj:any;
    if(!this.appDataService.dashboardData.graph6 || this.commonService.getloggedData().role == "Superadmin"){
      this.appDataService.dashboardData.graph6 = this.mergeDataDonut();
    }

    obj = this.appDataService.dashboardData.graph6;
    console.log(obj);
    this.doughnutChartLabels = obj.labels;
    this.doughnutChartData = [
      { data: obj.data, backgroundColor: ['#004551','#005261','#006072','#006e82','#007c92','#008aa3','#0097b3','#00a5c3','#00b3d4', '#00c1e4', "#00cff4", "#06d8ff",'#16dbff','#26ddff','#37e0ff','#47e2ff','#57e5ff','#68e7ff', '#78eaff', "#88ecff", "#99efff", "#a9f1ff", "#b9f4fe", "#c9f6fe", "#daf9fe", "#eafbff"],hoverBorderColor: '#E0E0E0', hoverBorderWidth: 5, borderWidth: 0, borderColor: ['#004551','#005261','#006072','#006e82','#007c92','#008aa3','#0097b3','#00a5c3','#00b3d4', '#00c1e4', "#00cff4", "#06d8ff",'#16dbff','#26ddff','#37e0ff','#47e2ff','#57e5ff','#68e7ff', '#78eaff', "#88ecff", "#99efff", "#a9f1ff", "#b9f4fe", "#c9f6fe", "#daf9fe", "#eafbff"] },
    ];
    this.doughnutChartType = 'doughnut';
    this.doughnutChartOptions= {
      responsive: true,
      tooltips: {
       enabled: true,
       callbacks: {
        label: function (tooltipItem, data) {
         let label = data.labels[tooltipItem.index];
         let count: any = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
         count = parseFloat(count).toFixed(2);
         return label + " Consumed: " + count;
        },
       },
      },
      maintainAspectRatio: false,
      animation: {
        duration: 2500,
      },
      legend: {
        position: 'right',
        labels: {
          fontSize: 8,
          usePointStyle: true
        }
      }
    };
    this.doughnutChartLegend = (!this.isMobile)?true:false;
  }

  public async drawBar(){
    let lastday = new Date();
    let end = (moment(lastday, "M/D/YYYY HH:mm:ss").valueOf())/1000;
    let start = end - (86400*(6+Number(moment().isoWeekday())));

    let resp1:any;
    if(!this.appDataService.dashboardData.graph7 || this.commonService.getloggedData().role == "Superadmin"){
      console.log("API called for graph 7");
      const params1 = {uuid:'rc000010',measurement:'power',range_start:start,range_end:end,type:'em',aggregation:'daily',timezone:'Asia/Kolkata',single:true};
      resp1 = await this.appDataService.multipleData('Device',params1,this.mainObj,true);
      this.appDataService.dashboardData.graph7 = resp1;
    }else{
      console.log("No API called for graph 7");
      resp1 = this.appDataService.dashboardData.graph7;
    }

    this.barChartOptions = { 
      responsive: true,
      tooltips: {
       enabled: true,
       callbacks: {
        label: function (tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label;
          let count: any = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          count = parseFloat(count).toFixed(2);
          return label + " " + count;
        },
       },
      },
      scales:{yAxes:[{display: false}]},
      maintainAspectRatio: false,
      animation: {
        duration: 5000,
      }
    };
    this.barChartLabels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat','Sun'];
    this.barChartType = 'bar';
    this.barChartLegend = (!this.isMobile)?true:false;

    let barData: any;
    if(!resp1 || this.commonService.getloggedData().role == "Superadmin"){
      console.log(resp1.obj1);
      let mondayArr = new Array();
      let index: number = 0;
      for (let i=0; i<resp1.obj1.length; i++) {
        if (resp1.obj1[i] != 0) {
          index = 0;
          break;
        }
      }
      for (let i=0; i<resp1.obj1[index].length; i++) {
        const date = new Date(resp1.obj1[index][i][0]*1000);

        let d1 = new Date(date.getFullYear(),date.getMonth(),date.getDate(),date.getHours(),date.getMinutes(),date.getSeconds());
        let day = moment(d1).day();
        if(day == 1){
          mondayArr.push(resp1.obj1[index][i][0]);
        }
      }

      let mondayTS: number;
      if (mondayArr.length == 1) {
        mondayTS = mondayArr[0];
      } else if (mondayArr.length > 1) {
        mondayTS = mondayArr[mondayArr.length - 2];
      }
      // console.log("Including from: ", mondayTS);

      let ownData: any[] = new Array();
      for (let i=0; i<resp1.obj1.length; i++) {
        if (resp1.obj1[i] != 0) {
          let bool = false;
          let Arr: any[] = new Array();
          for (let j=0; j<resp1.obj1[i].length; j++) {
            if (resp1.obj1[i][j][0] == mondayTS) {
              bool = true;
            }

            if (bool == true) {
              Arr.push(resp1.obj1[i][j]);
            }
          }
          ownData.push(Arr);
        }
      }
      // console.log(ownData);

      barData = new Array();
      for (let i=0; i<ownData[0].length; i++) {
        let sum: number = 0;
        for (let j=0; j<ownData.length; j++) {
          for (let k=1; k<ownData[j][i].length; k++) {
            // console.log(ownData[j][i][k]);
            sum += ownData[j][i][k];
          }
        }
        let arr: any[] = [ownData[0][i][0], sum];
        barData.push(arr);
      }
      // console.log(barData);

      this.appDataService.dashboardData.graph7 = barData;
    } else {
      barData = resp1;
    }

    let week1Data: any[] = new Array();
    let week2Data: any[] = new Array();
    console.log("Data: ", barData);
    if (barData.obj1.length > 7) {
      for (let i=0; i<14; i++) {
        if (i < 7) {
          week1Data.push(barData.obj1[i].value1);
        } else {
          if(!!barData.obj1[i] && !!barData.obj1[i].value1){
            week2Data.push(barData.obj1[i].value1);
          }else{
            week2Data.push(0);
          }
        }
      }
    } else {
      for (let i=0; i<barData.obj1.length; i++) {
        week1Data.push(barData.obj1[i].value1);
      }
    }

    if (week2Data.length != 0) {
      while (week2Data.length < 7) {
        week2Data.push(0);
      }

      this.barChartData = [
        { data: week2Data, label: 'Consumption this week(kWh)', stack: 'a', hoverBorderColor: '#f5f5f5', hoverBorderWidth: 3 },
        { data: week1Data, label: 'Consumption last week(kWh)', stack: 'a', hoverBorderColor: '#f5f5f5', hoverBorderWidth: 3 }
      ];
    } else {
      while (week1Data.length < 7) {
        week1Data.push(0);
      }

      this.barChartData = [
        { data: week1Data, label: 'Consumption this week(kWh)', stack: 'a', hoverBorderColor: '#f5f5f5', hoverBorderWidth: 3 }
      ];
    }
    // this.barChartColors = [{ backgroundColor: '#FAC024' },{ backgroundColor: '#1D8348' }];
    this.barChartColors = [{ backgroundColor: '#1D8348' },{ backgroundColor: '#5392A8' }];
  }

  public async drawHorBar(){
    let lastday = new Date();
    let end = (moment(lastday, "M/D/YYYY HH:mm:ss").valueOf())/1000;
    let backdate = moment().subtract(2, 'days').startOf('day');
    let start = (moment(backdate, "M/D/YYYY HH:mm:ss").valueOf())/1000;

    let resp1:any;
    if(!this.appDataService.dashboardData.graph8 || this.commonService.getloggedData().role == "Superadmin"){
      console.log("API called for graph 8");
      const params1 = {uuid:'rc000010',measurement:'power',range_start:start,range_end:end,type:'em',aggregation:'daily',timezone:'Asia/Kolkata',single:true};
      resp1 = await this.overviewService.multipleDeviceData(params1,this.mainObj);
      this.appDataService.dashboardData.graph8 = resp1;
    }else{
      console.log("No API called for graph 8");
      resp1 = this.appDataService.dashboardData.graph8;
    }

    this.horBarChartOptions = {
      responsive: true,
      tooltips: {
       enabled: true,
       callbacks: {
        label: function (tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label;
          let count: any = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          count = parseFloat(count).toFixed(2);
          return label + " Consumed: " + count;
        },
       },
      },
      scales:{yAxes:[{display: false}]},
      maintainAspectRatio: false,
      animation: {
        duration: 5000,
      },
      legend: {
        position: 'right',
        labels: {
          fontSize: 8,
          usePointStyle: true
        }
      }
    };
    this.horBarChartType = 'bar';
    this.horBarChartLegend = (!this.isMobile)?true:false;
    this.horBarChartData = new Array();
    //decreasing the limit to 10 to adjust UI
    let allowedDevices = this.allowedDeviceNames.length>=9 ? 9 : this.allowedDeviceNames.length;
    for(let i=0;i<allowedDevices;i++){
      let data = [];
      for(let j=0;j<3;j++){
        let name = this.allowedDeviceNames[i].split(' ').join('');
        data.push(resp1.obj1[j][name]); 
      }
      this.horBarChartData.push({
        data: data,
        label: this.allowedDeviceNames[i],
        stack: 'a',
        hoverBorderColor: '#E0E0E0',
        hoverBorderWidth: 2
      });
    }

    this.horBarChartLabels = ['Day before Yesterday', 'Yesterday', 'Today'];
    this.horBarChartColors = [{ backgroundColor: '#fdf8eb' },{ backgroundColor: '#fcf3dc' },{ backgroundColor: '#fbeecd' },{ backgroundColor: '#fae8be' },{ backgroundColor: '#f9e3ae' },{ backgroundColor: '#f8de9f' },{ backgroundColor: '#f7d990' },{ backgroundColor: '#f6d481' },{ backgroundColor: '#f5ce71' },{ backgroundColor: '#f4c962' },{ backgroundColor: '#f3c453' },{ backgroundColor: '#f2bf44' },{ backgroundColor: '#f0ba34' },{ backgroundColor: '#efb425' },{ backgroundColor: '#eeaf16' },{ backgroundColor: '#e4a60f' },{ backgroundColor: '#d59b0e' },{ backgroundColor: '#c6900d' },{ backgroundColor: '#b7850c' },{ backgroundColor: '#a77a0b' },{ backgroundColor: '#986f0a' },{ backgroundColor: '#896409' },{ backgroundColor: '#7a5808' },{ backgroundColor: '#6a4d07' },{ backgroundColor: '#5b4206' },{ backgroundColor: '#4c3705' }];
  }

  public async drawRadar(){
    const params = {uuid:'rc000010',measurement:'power',range_start:'1605958440',range_end:'1605958840',type:'i1,i2,i3',aggregation:''};
    const resp:any = await this.httpService.getApi(params);
    let obj:any = this.appDataService.dashboardData.graph6;
    const params1 = {uuid:'rc000010',measurement:'power',range_start:'1605958440',range_end:'1605958840',type:'u1,u2,u3',aggregation:''};
    const resp1 :any = await this.httpService.getApi(params1);
    let obj1:any = this.appDataService.dashboardData.graph6; 
    this.radarChartType = 'radar';
    this.radarChartOptions = { 
      responsive: true,
      legend: { display: false },
      animation: {
        duration: 5000,
      }
    };
    this.radarChartLabels = obj.labels;
    this.radarChartData = [
      { data: obj.data, label: 'This week',pointHoverBorderColor: 'pink',pointHoverBorderWidth: 10,pointHoverRadius: 10 },
      { data: obj1.data, label: 'Last Week',pointHoverBorderColor: 'steelblue',pointHoverBorderWidth: 10,pointHoverRadius: 10 }
    ];
  }

  public async drawSteppedLine(){
    var now = new Date();
    let current_timestamp = Math.floor(Date.now() / 1000);
    let rt_timestamp = current_timestamp - 7200;
    let day: any = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    let day_timestamp = Math.round(day / 1000);
    let month: any = new Date(now.getFullYear(), now.getMonth());
    let month_timestamp = Math.round(month / 1000);
    let year: any = new Date(now.getFullYear(), 0, 1);
    let year_timestamp = Math.round(year / 1000);

    //------------------------------------1st-----------------------
    try {
      let resp1:any;
      if(!this.appDataService.dashboardData.graph1 || this.commonService.getloggedData().role == "Superadmin"){
        console.log("API called for graph 1");
        const params1 = {uuid:'rc000010',measurement:'power',range_start:rt_timestamp,range_end:current_timestamp - 900,type:'em',aggregation:'',timezone:'Asia/Kolkata',single:true};
        resp1 = await this.appDataService.multipleData('Device',params1,this.mainObj,true);
        this.appDataService.dashboardData.graph1 = resp1;
      }else{
        console.log("No API called for graph 1");
        resp1 = this.appDataService.dashboardData.graph1;
      }
      console.log(resp1);

      this.graph1Total = 0;
      this.steppedLineChartLabels1 = [];
      const dataArr1 = [];

      if(!!resp1.obj1 && resp1.obj1.length>0){//api error message
        for(let i=0;i<resp1.obj1.length;i++){
          const date = resp1.obj1[i].time1;
          let d1 = moment(date).format("MMMM DD, YYYY HH:mm:ss");
          this.steppedLineChartLabels1.push(d1);
          dataArr1.push(resp1.obj1[i].value1);
          // this.graph1Total = this.graph1Total + (!!resp1.obj1[i].value1?resp1.obj1[i].value1:0);
        }
        if (!!resp1.obj1[0].value1 && !!resp1.obj1[resp1.obj1.length-1].value1) {
          if (resp1.obj1[resp1.obj1.length-2].value1 > resp1.obj1[resp1.obj1.length-1].value1)
            this.graph1Total = resp1.obj1[resp1.obj1.length-2].value1 - resp1.obj1[0].value1;
          else
            this.graph1Total = resp1.obj1[resp1.obj1.length-1].value1 - resp1.obj1[0].value1;
        }
        this.graph1Total = this.commonService.unitConversion(this.graph1Total);
  
        this.steppedLineChartData1 =  [
          { data: [-1, -1, -1, -1],lineTension: 0, hidden: true },
          { data: dataArr1, label: 'Weekly',lineTension: 0,borderWidth: 1 }
        ];
        this.steppedLineChartOptions1 = {
          responsive: true,
          tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              let label = data.datasets[1].label;
            let count: any = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            count = parseFloat(count).toFixed(2);
            return label + " " + count;
            },
          },
          },
          scales:{xAxes:[{display: false}],yAxes:[{display: false}]},
          legend: { display: false },
          maintainAspectRatio: false,
          animation: {duration: 5000},
          elements: { point:{ radius: 0 } }
        };
        this.steppedLineChartColors1 = [{borderColor: 'black',backgroundColor: 'rgba(54,162,235,0.4)'}]; //mediumpurple
        this.steppedLineChartLegend1 = true;
        this.steppedLineChartType1 = 'line';
        this.steppedLineChartPlugins1 = [];
      }else{
        this.steppedLineChartData1 = null;
      }
    } catch { }

    //---------------------------------------2nd--------------------
    try {
      let resp2:any;
      if(!this.appDataService.dashboardData.graph2 || this.commonService.getloggedData().role == "Superadmin"){
        console.log("API called for graph 2");
        const params2 = {uuid:'rc000010',measurement:'power',range_start:day_timestamp,range_end:current_timestamp,type:'em',aggregation:'hourly',timezone:'Asia/Kolkata',single: true};
        resp2 = await this.appDataService.multipleData('Device',params2,this.mainObj,true);
        this.appDataService.dashboardData.graph2 = resp2;
      }else{
        console.log("No API called for graph 2");
        resp2 = this.appDataService.dashboardData.graph2;
      }

      this.graph2Total = 0;
      this.steppedLineChartLabels2 = [];
      const dataArr2 = [];

      if(!!resp2.obj1 && resp2.obj1.length>0){//api error message
        for(let i=0;i<resp2.obj1.length;i++){
          const date = resp2.obj1[i].time1;
          let d1 = moment(date).format("MMMM DD, YYYY HH:mm:ss");
          this.steppedLineChartLabels2.push(d1);
          dataArr2.push(resp2.obj1[i].value1);
          this.graph2Total = this.graph2Total + (!!resp2.obj1[i].value1?resp2.obj1[i].value1:0);
        }
        this.graph2Total = this.graph2Total;
        this.graph2Total = this.commonService.unitConversion(this.graph2Total);
  
        this.steppedLineChartData2 = [
          { data: [-1, -1, -1, -1],lineTension: 0, hidden: true },
          { data: [-1, -1, -1, -1],lineTension: 0, hidden: true },
          { data: dataArr2, label: 'Monthly',lineTension: 0,borderWidth: 1 }
        ];
  
        this.steppedLineChartOptions2 = {
          responsive: true,
          tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              let label = data.datasets[2].label;
            let count: any = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            count = parseFloat(count).toFixed(2);
            return label + " " + count;
            },
          },
          },
          scales:{xAxes:[{display: false}],yAxes:[{display: false}]},
          legend: { display: false },
          maintainAspectRatio: false,
          animation: {duration: 5000},
          elements: { point:{ radius: 0 } }
        };
        this.steppedLineChartColors2 = [{borderColor: 'black',backgroundColor: 'tomato'}];
        this.steppedLineChartLegend2 = true;
        this.steppedLineChartType2 = 'line';
        this.steppedLineChartPlugins2 = [];
      }else{
        this.steppedLineChartData2 = null;
      }
    } catch { }

    //-----------------------------------------3rd------------------
    try {
      let resp3:any;
      if(!this.appDataService.dashboardData.graph3 || this.commonService.getloggedData().role == "Superadmin"){ 
        console.log("API called for graph 3");
        const params3 = {uuid:'rc000010',measurement:'power',range_start:month_timestamp,range_end:current_timestamp,type:'em',aggregation:'daily',timezone:'Asia/Kolkata',single:true};
        resp3 = await this.appDataService.multipleData('Device',params3,this.mainObj,true,true);
        this.appDataService.dashboardData.graph3 = resp3;
      }else{
        console.log("No API called for graph 3");
        resp3 = this.appDataService.dashboardData.graph3;
      }

      this.graph3Total = 0;
      this.steppedLineChartLabels3 = [];
      const dataArr3 = [];

      if(!!resp3.obj1 && resp3.obj1.length>0){//api error message
        for(let i=0;i<resp3.obj1.length;i++){
          const date = resp3.obj1[i].time1;
          let d1 = moment(date).format("MMMM DD, YYYY HH:mm:ss");
          this.steppedLineChartLabels3.push(d1);
          dataArr3.push(resp3.obj1[i].value1);
          this.graph3Total = this.graph3Total + (!!resp3.obj1[i].value1?resp3.obj1[i].value1:0);
        }
        this.graph3Total = this.graph3Total;
        this.graph3Total = this.commonService.unitConversion(this.graph3Total);
  
        this.steppedLineChartData3 = [
          { data: [-1, -1, -1, -1],lineTension: 0, hidden: true },
          { data: [-1, -1, -1, -1],lineTension: 0, hidden: true },
          { data: [-1, -1, -1, -1],lineTension: 0, hidden: true },
          { data: [-1, -1, -1, -1],lineTension: 0, hidden: true },
          { data: [-1, -1, -1, -1],lineTension: 0, hidden: true },
          { data: [-1, -1, -1, -1],lineTension: 0, hidden: true },
          { data: [-1, -1, -1, -1],lineTension: 0, hidden: true },
          { data: dataArr3, label: 'Quaterly',lineTension: 0,borderWidth: 1 }
        ];
        
        this.steppedLineChartOptions3 = {
          responsive: true,
          tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              let label = data.datasets[7].label;
            let count: any = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            count = parseFloat(count).toFixed(2);
            return label + " " + count;
            },
          },
          },
          scales:{xAxes:[{display: false}],yAxes:[{display: false}]},
          legend: { display: false },
          maintainAspectRatio: false,
          animation: {duration: 5000},
          elements: { point:{ radius: 0 } }
        };
        this.steppedLineChartColors3 = [{borderColor: 'tomato',backgroundColor: 'tomato'}]; //rgba(255,206,86,0.4)
        this.steppedLineChartLegend3 = true;
        this.steppedLineChartType3 = 'line';
        this.steppedLineChartPlugins3 = [];
      }else{
       this.steppedLineChartData3 = null; 
      }
    } catch { } 

    //-------------------------------------------4th----------------
    try {
      let resp4:any;
      if(!this.appDataService.dashboardData.graph4 || this.commonService.getloggedData().role == "Superadmin"){ 
        console.log("API called for graph 4");
        const params4 = {uuid:'rc000010',measurement:'power',range_start:year_timestamp,range_end:current_timestamp,type:'em',aggregation:'monthly',timezone:'Asia/Kolkata',single:true};
        resp4 = await this.appDataService.multipleData('Device',params4,this.mainObj,true);
        this.appDataService.dashboardData.graph4 = resp4;
      }else{
        console.log("No API called for graph 3");
        resp4 = this.appDataService.dashboardData.graph4;
      }

      this.graph4Total = 0;
      this.steppedLineChartLabels4 = [];
      const dataArr4 = [];

      if(!!resp4.obj1 && resp4.obj1.length>0){//api error message
        for(let i=0;i<resp4.obj1.length;i++){
          const date = resp4.obj1[i].time1;
          let d1 = moment(date).format("MMMM DD, YYYY HH:mm:ss");
          this.steppedLineChartLabels4.push(d1);
          dataArr4.push(resp4.obj1[i].value1);
          this.graph4Total = this.graph4Total + (!!resp4.obj1[i].value1?resp4.obj1[i].value1:0);
        }
        this.graph4Total = this.graph4Total;
        this.graph4Total = this.commonService.unitConversion(this.graph4Total);
  
        this.steppedLineChartData4 = [
          { data: [-1, -1, -1, -1],lineTension: 0, hidden: true },
          { data: [-1, -1, -1, -1],lineTension: 0, hidden: true },
          { data: [-1, -1, -1, -1],lineTension: 0, hidden: true },
          { data: [-1, -1, -1, -1],lineTension: 0, hidden: true },
          { data: dataArr4, label: 'Yearly',lineTension: 0,borderWidth: 1 }
        ];
  
        this.steppedLineChartOptions4 = {
          responsive: true,
          tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              console.log(data);
              let label = data.datasets[4].label;
            let count: any = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            count = parseFloat(count).toFixed(2);
            return label + " " + count;
            },
          },
          },
          scales:{xAxes:[{display: false}],yAxes:[{display: false}]},
          legend: { display: false },
          maintainAspectRatio: false,
          animation: {duration: 5000},
          elements: { point:{ radius: 0 } }
        };
        this.steppedLineChartColors4 = [{borderColor: 'black',backgroundColor: 'darkseagreen'}];//rgba(231,233,237,0.4)
        this.steppedLineChartLegend4 = true;
        this.steppedLineChartType4 = 'line';
        this.steppedLineChartPlugins4 = [];
      }else{
        this.steppedLineChartData4 = null;
      }
    } catch { }
  }  

  onGraphClick(graph){
    let data:any = {};
    data.resp = this.resp;
    if(graph == 'linechart'){
      data.datasets = this.lineChartData;
      data.labels = this.lineChartLabels;
      data.options = this.lineChartOptions;
      data.legend = true;
      data.chartType = 'line';
      data.plugins = this.lineChartPlugins;
    }else if(graph == 'radar'){
      data.datasets = this.radarChartData;
      data.labels = this.radarChartLabels;
      data.options = this.radarChartOptions;
      data.legend = null;
      data.chartType = this.radarChartType;
      data.plugins = true;
    }else if(graph == 'doughnut'){
      data.datasets = this.doughnutChartData;
      data.labels = this.doughnutChartLabels;
      data.options = this.doughnutChartOptions;
      data.legend = true;
      data.chartType = this.doughnutChartType;
      data.plugins = null;
    }else if(graph == 'bar1'){
      data.datasets = this.barChartData;
      data.labels = this.barChartLabels;
      data.options = this.barChartOptions;
      data.legend = true;
      data.chartType = this.barChartType;
      data.colors = this.barChartColors;
      data.plugins = null;
    }else if(graph == 'bar2'){
      data.datasets = this.horBarChartData;
      data.labels = this.horBarChartLabels;
      data.options = this.horBarChartOptions;
      data.legend = true;
      data.colors = this.horBarChartColors;
      data.chartType = this.horBarChartType;
      data.plugins = null;
    }

    this.commonService.setGraphData(data);
    this.commonService.setTabColor("consumption")
    this.route.navigate(['/landing/consumption'],{queryParams:{}});
  }

  public mergeDataDonut(){
    if(!this.appDataService.rawData || (!!this.appDataService.rawData && this.appDataService.rawData.length==0)){//api error message
      this.barChartData = null;
    }

    console.log(this.appDataService.rawData);
    let obj = {};
    let dataMainArr = [];

    for (let i=0; i<this.appDataService.rawData.length; i++) {
      let sum: number = 0;
      for (let j=0; j<this.appDataService.rawData[i].length; j++) {
        for (let k=1; k<this.appDataService.rawData[i][j].length; k++) {
          sum += this.appDataService.rawData[i][j][k];
        }
      }
      dataMainArr.push(sum);
    }
    let labelArr: any[] = this.allowedDeviceNames.slice();

    for (let i=0; i<dataMainArr.length-1; i++) {
      for (let j=i+1; j<dataMainArr.length; j++) {
        if (dataMainArr[i] < dataMainArr[j]) {
          let temp: any = dataMainArr[i];
          dataMainArr[i] = dataMainArr[j];
          dataMainArr[j] = temp;

          temp = labelArr[i];
          labelArr[i] = labelArr[j];
          labelArr[j] = temp;
        }
      }
    }

    this.appDataService.donutLabel = labelArr;
    this.appDataService.donutData = [
      // { data: obj.data, backgroundColor: ['#EB1515','#7C383E','#F40505','#F0610A','#FA5E6C','#900EEA','#5F2E4A','#EBDB13','#E50D8A','#958E3A','#DE1AE7','#B7EF0A','#7E9537','#4FE811','#3E8024','#131CE1','#0BEA81','#2B6248','#0CE9D2','#46837D','#4F2D67','#0DC2E6','#458592','#1181E4','#385F82'],hoverBorderColor: '#E0E0E0', hoverBorderWidth: 5, borderWidth: 0 },
      { data: dataMainArr, backgroundColor: ['#4c3705','#5b4206','#6a4d07','#7a5808','#896409','#986f0a','#a77a0b','#b7850c','#c6900d','#d59b0e','#e4a60f','#eeaf16','#efb425', '#f0ba34', "#f2bf44", "#f3c453",'#f4c962','#f5ce71','#f6d481','#f7d990','#f8de9f','#f9e3ae', '#fae8be', "#fbeecd", "#fcf3dc", "#fdf8eb"],hoverBorderColor: '#E0E0E0', hoverBorderWidth: 5, borderWidth: 0 },
      
    ];

    if (dataMainArr.length >= 9) {
      dataMainArr = dataMainArr.slice(0, 9);
      labelArr = labelArr.slice(0, 9);
    }

    dataMainArr = dataMainArr.filter(val => val !== 0);

    obj = {data:dataMainArr, labels:labelArr};
    this.labelArr = labelArr;
    return obj;
  }

  //guage graph
  drawGauge() {
    var self = this;
    var gauge = function (container, configuration) {

      var config = {
        size: 200,
        clipWidth: 200,
        clipHeight: 200,
        ringInset: 5,
        ringWidth: 20,

        pointerWidth: 10,
        pointerTailLength: 12,
        pointerHeadLengthPercent: 1.0,

        minValue: 0,
        maxValue: 10,

        minAngle: -75,
        maxAngle: 75,

        transitionMs: 750,

        majorTicks: 5,
        labelFormat: d3.format('d'),
        labelInset: 5,

        arcColorFn: d3.scaleOrdinal().range(["#e8e2ca", "#3e6c0a"])
      };
      var range = undefined;
      var r = undefined;
      var pointerHeadLength = undefined;
      var value = 0;

      var svg = undefined;
      var arc = undefined;
      var scale = undefined;
      var ticks = undefined;
      var tickData = undefined;
      var pointer = undefined;

      var donut = d3.pie();

      function deg2rad(deg) {
        return deg * Math.PI / 180;
      }

      function configure(configuration) {
        var prop = undefined;
        for (prop in configuration) {
          config[prop] = configuration[prop];
        }

        range = config.maxAngle - config.minAngle;
        r = config.size / 2;
        pointerHeadLength = Math.round(r * config.pointerHeadLengthPercent);

        // a linear scale this.gaugemap maps domain values to a percent from 0..1
        scale = d3.scaleLinear()
          .range([0, 1])
          .domain([config.minValue, config.maxValue]);

        ticks = scale.ticks(config.majorTicks);
        tickData = d3.range(config.majorTicks).map(function () { return 1 / config.majorTicks; });

        arc = d3.arc()
          .innerRadius(r - config.ringWidth - config.ringInset)
          .outerRadius(r - config.ringInset)
          .startAngle(function (d, i) {
            var ratio = d * i;
            return deg2rad(config.minAngle + (ratio * range));
          })
          .endAngle(function (d, i) {
            var ratio = d * (i + 1);
            return deg2rad(config.minAngle + (ratio * range));
          });
      }
      self.gaugemap.configure = configure;

      function centerTranslation() {
        return 'translate(' + r + ',' + r + ')';
      }

      function isRendered() {
        return (svg !== undefined);
      }
      self.gaugemap.isRendered = isRendered;

      function render(newValue) {
        svg = d3.select(container)
          .append('svg')
          .attr('class', 'gauge')
          // .style('position', 'absolute')
          // .attr('width', '100px')
          // .attr('height', '100px')
          // .attr('viewBox', '0' + ' ' + '0'+ ' ' + config.clipWidth + ' ' + config.clipHeight)
          .attr('preserveAspectRatio', 'xMinYMin');
          
        var centerTx = centerTranslation();

        var arcs = svg.append('g')
          .attr('class', 'arc')
          .attr('transform', centerTx);

          let cnt = -1;
          let cnt1 = -1;
          arcs.selectAll('path')
            .data(tickData)
            .enter().append('path')
            .attr('fill', function (d, i) {
              cnt++;
              return (cnt < 3)?'#212420':'#1D8348';
            })
            .attr('stroke', function (d, i) {
              cnt1++;
              return (cnt1 < 3)?'#212420':'#1D8348';
            })
            .attr('d', arc);

        var lg = svg.append('g')
          .attr('class', 'label')
          .attr('transform', centerTx);
        lg.selectAll('text')
          .data(ticks)
          .enter().append('text')
          .attr('transform', function (d) {
            var ratio = scale(d);
            var newAngle = config.minAngle + (ratio * range);
            return 'rotate(' + newAngle + ') translate(0,' + (config.labelInset - r) + ')';
          })
          .text(config.labelFormat);

        var lineData = [[config.pointerWidth / 2, 0],
        [0, -pointerHeadLength],
        [-(config.pointerWidth / 2), 0],
        [0, config.pointerTailLength],
        [config.pointerWidth / 2, 0]];
        var pointerLine = d3.line().curve(d3.curveLinear)
        var pg = svg.append('g').data([lineData])
          .attr('class', 'pointer')
          .attr('transform', centerTx)
          .attr('fill', 'white');

        pointer = pg.append('path')
          .attr('d', pointerLine/*function(d) { return pointerLine(d) +'Z';}*/)
          .attr('transform', 'rotate(' + config.minAngle + ')');

        update(newValue === undefined ? 0 : newValue);
      }
      self.gaugemap.render = render;
      function update(newValue, newConfiguration?) {
        if (newConfiguration !== undefined) {
          configure(newConfiguration);
        }
        var ratio = scale(newValue);
        var newAngle = config.minAngle + (ratio * range);
        pointer.transition()
          .duration(config.transitionMs)
          .ease(d3.easeElastic)
          .attr('transform', 'rotate(' + newAngle + ')');
      }
      self.gaugemap.update = update;

      configure(configuration);

      return self.gaugemap;
    };

    var powerGauge1 = gauge('#power-gauge1', {
      size: 200,
      clipWidth: 200,
      clipHeight: 200,
      ringWidth: 60,
      maxValue: 10,
      transitionMs: 10000,
    });
    powerGauge1.render(2);

    var powerGauge2 = gauge('#power-gauge2', {
        size: 200,
        clipWidth: 200,
        clipHeight: 200,
        ringWidth: 60,
        maxValue: 10,
        transitionMs: 10000,
    });
    powerGauge2.render(5);

    var powerGauge3 = gauge('#power-gauge3', {
        size: 200,
        clipWidth: 200,
        clipHeight: 200,
        ringWidth: 60,
        maxValue: 10,
        transitionMs: 10000,
    });
    powerGauge3.render(3);

    var powerGauge4 = gauge('#power-gauge4', {
        size: 200,
        clipWidth: 200,
        clipHeight: 200,
        ringWidth: 60,
        maxValue: 10,
        transitionMs: 10000,
    });
    powerGauge4.render(7);

  }

  public drawMaps(){
    // Create map instance
    var chart = am4core.create("chartdiv", am4maps.MapChart);

    // Set map definition
    chart.geodata = am4geodata_worldLow;

    // Set projection
    chart.projection = new am4maps.projections.Miller();

    // Create map polygon series
    var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

    // Make map load polygon (like country names) data from GeoJSON
    polygonSeries.useGeodata = true;

    // Configure series
    var polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{name}";
    polygonTemplate.fill = am4core.color("#74B266");

    // Create hover state and set alternative fill color
    var hs = polygonTemplate.states.create("hover");
    hs.properties.fill = am4core.color("#367B25");

    // Remove Antarctica
    polygonSeries.exclude = ["AQ"];

    // Add some data
    // if (this.commonService.getloggedData().role != "Superadmin") {
    //   polygonSeries.data = [{
    //     "id": "US",
    //     "name": "United States",
    //     "value": 100,
    //     "fill": am4core.color("#F05C5C")
    //   }, {
    //     "id": "FR",
    //     "name": "France",
    //     "value": 50,
    //     "fill": am4core.color("#5C5CFF")
    //   }];
    // } else {
    //   polygonSeries.data = [{
    //     "id": "US",
    //     "name": "United States",
    //     "value": 100,
    //     "fill": am4core.color("#F05C5C")
    //   }, {
    //     "id": "FR",
    //     "name": "France",
    //     "value": 50,
    //     "fill": am4core.color("#5C5CFF")
    //   }];
    // }

    // Bind "fill" property to "fill" key in data
    polygonTemplate.propertyFields.fill = "fill";

    // Create image series
    var imageSeries = chart.series.push(new am4maps.MapImageSeries());

    // Create a circle image in image series template so it gets replicated to all new images
    var imageSeriesTemplate = imageSeries.mapImages.template;
    var circle = imageSeriesTemplate.createChild(am4core.Circle);
    circle.radius = 4;
    circle.fill = am4core.color("#B27799");
    circle.stroke = am4core.color("#FFFFFF");
    circle.strokeWidth = 2;
    circle.nonScaling = true;
    circle.tooltipText = "{title}";

    // Set property fields
    imageSeriesTemplate.propertyFields.latitude = "latitude";
    imageSeriesTemplate.propertyFields.longitude = "longitude";

    // Add data for the three cities
    imageSeries.data = new Array();
    // if (this.commonService.getloggedData().role != "Superadmin") {
    //   let data: any = this.commonService.getCompanyData();

    //   for (let i=0; i<eval(data[0].premises).length; i++) {
    //     if (this.commonService.getloggedData().premises.includes(eval(data[0].premises)[i])) {
    //       imageSeries.data.push({
    //         "latitude": parseFloat(eval(data[0].location)[i].split('X')[0]),
    //         "longitude": parseFloat(eval(data[0].location)[i].split('X')[1]),
    //         "title": eval(data[0].premises)[i]
    //       })
    //     }
    //   }
    //   // console.log(imageSeries.data);
    // } else {
    //   let data: any = this.commonService.getCompanyData();

    //   for (let i=0; i<data.length; i++) {
    //     for (let j=0; j<eval(data[i].premises).length; j++) {
    //       imageSeries.data.push({
    //         "latitude": parseFloat(eval(data[i].location)[j].split('X')[0]),
    //         "longitude": parseFloat(eval(data[i].location)[j].split('X')[1]),
    //         "title": data[i].name + ' - ' + eval(data[i].premises)[j]
    //       })
    //     }
    //   }
    // }

    let data: any = this.commonService.getCompanyData()[this.selectedIndex];

    for (let i=0; i<eval(data.premises).length; i++) {
      if (this.commonService.getloggedData().role != "Superadmin") {
        if (this.commonService.getloggedData().premises.includes(eval(data.premises)[i])) {
          imageSeries.data.push({
            "latitude": parseFloat(eval(data.location)[i].split('X')[0]),
            "longitude": parseFloat(eval(data.location)[i].split('X')[1]),
            "title": eval(data.premises)[i]
          });
        }
      } else {
        imageSeries.data.push({
          "latitude": parseFloat(eval(data.location)[i].split('X')[0]),
          "longitude": parseFloat(eval(data.location)[i].split('X')[1]),
          "title": eval(data.premises)[i]
        });
      }
    }
    // console.log(imageSeries.data);
  }

  onCompanySelect(event){
    this.isCompanySelected = true;
    this.selectedCompany = event.value;
    for (let key in this.companies){
      if (eval(this.companies)[key].name == event.value){
        this.selectedIndex = key;
        break;
      }
    }

    let num: number = 0;
    this.allowedPremise = new Array();
    this.premiseWise = new Array();
    this.allowedDevices = new Array();
    this.allowedDeviceNames = new Array();
    for (let i=0; i<eval(this.commonService.getCompanyData()[this.selectedIndex].premises).length; i++) {
      this.allowedPremise.push(eval(this.commonService.getCompanyData()[0].premises)[i]);
      let Arr = new Array();
      for (let j=0; j<eval(eval(this.commonService.getCompanyData()[this.selectedIndex].floor)[i]).length; j++) {
        for (let k=0; k<eval(eval(eval(this.commonService.getCompanyData()[this.selectedIndex].device_id)[i])[j]).length; k++) {
          Arr.push(num++);
          this.allowedDevices.push(eval(eval(eval(this.commonService.getCompanyData()[this.selectedIndex].device_id)[i])[j])[k]);
          this.allowedDeviceNames.push(eval(eval(eval(this.commonService.getCompanyData()[this.selectedIndex].device_name)[i])[j])[k]);
          this.mainObj.obj1.device.push(eval(eval(eval(this.commonService.getCompanyData()[this.selectedIndex].device_id)[i])[j])[k]);
          this.mainObj.obj1.phase.push(eval(eval(eval(this.commonService.getCompanyData()[this.selectedIndex].device_phase)[i])[j])[k]);
          this.mainObj.obj1.devicename.push(eval(eval(eval(this.commonService.getCompanyData()[this.selectedIndex].device_name)[i])[j])[k]);
        }
      }
      this.premiseWise.push(Arr);
    }

    // this.filterData = this.data1;
    this.drawGraphs();
    // this.commonService.tabSize.subscribe(data => {
    //   console.log("tab size changed "+data);
    //   this.drawGraphs();
    // });
  }

  //-----------------------random ng2-charts color generator----------------------
  // public  defaultColors:Array<number[]> = [
  //   [255, 99, 132],
  //   [54, 162, 235],
  //   [255, 206, 86],
  //   [231, 233, 237],
  //   [75, 192, 192],
  //   [151, 187, 205],
  //   [220, 220, 220],
  //   [247, 70, 74],
  //   [70, 191, 189],
  //   [253, 180, 92],
  //   [148, 159, 177],
  //   [77, 83, 96]
  // ];

  // generateColor(index:number):number[] {
  //   return this.defaultColors[index];
  // }

  // rgba(colour:Array<number>, alpha:number):string {
  //   return 'rgba(' + colour.concat(alpha).join(',') + ')';
  // }

  // randomGenerator() {
  //   let colors = this.generateColor(1);//pass d index here
  //   let backgroundColor = this.rgba(colors, 0.4);
  //   console.log(backgroundColor);
  // }
  //-----------------------random ng2-charts color generator----------------------

}
