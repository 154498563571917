import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { PipesModule } from './common/pipes/Pipes.module';
import { GraphsModule } from './graphs/graph.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgDragDropModule } from 'ng-drag-drop';
import { ChartsModule } from 'ng2-charts';
import { BasicAuthInterceptor } from './common/services/BasicAuthInterceptor.service';
import { ConfirmComponent } from './common/components/confirm-box/confirm-box.page';
import { NgxPaginationModule } from 'ngx-pagination'; 
import { DatePipe } from '@angular/common';
import { OverviewModule } from './overview/overview.module';

@NgModule({
  declarations: [
    AppComponent,
    ConfirmComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    PipesModule,
    GraphsModule,
    ChartsModule,
    NgxPaginationModule,
    OverviewModule,
    NgDragDropModule.forRoot()
  ],
  providers: [DatePipe, { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },],
  bootstrap: [AppComponent]
})
export class AppModule { }
