import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService as AuthGuard } from './common/services/Authguard.service';

const routes:Routes = [
  { path: 'landing', loadChildren: './landing/landing.module#LandingModule', canActivate: [AuthGuard]  },
  { path: 'master', loadChildren: './master/master.module#MasterModule', canActivate: [AuthGuard]  },
  { path: 'login', loadChildren: './login/login.module#LoginModule' },
  { path: 'password', loadChildren: './password/password.module#PasswordModule', canActivate: [AuthGuard]  },
  { path: '', redirectTo: 'login', pathMatch: 'full'},
  { path: '**', redirectTo: 'login'},
  { path: 'full', redirectTo: 'login'}
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled', // Add options right here
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
