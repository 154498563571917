import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root' 
})
export class LoginService {
    uname:any;
    pass:any;
    isLogin = false; //to set headers in interceptor for login
    token:any;

    public setLoginData(uname,pass){
        this.uname = uname;
        this.pass = pass;
        // console.log(this.uname, this.pass);
    }

    public getLoginData(){
        // let obj = {Username: '7jrghmgk1rrmfeumbaf0oeg1hc', Password: '1m9cm5tehu33irc57pgn16lgjmhhs3bcfndt009jauohpgl87jlg'};        //hardcoded values
        let obj = {Username: this.uname, Password: this.pass};
        // console.log(obj)
        return obj;
    }

    public setToken(token,isLogin){
        this.token = token;
        this.isLogin = isLogin;
    }

}