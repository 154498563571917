import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import * as d3 from 'd3';
import { Axis, AxisDomain } from 'd3';
import { CommonService } from 'src/app/common/services/commonservice.service';
@Component({
  selector: 'app-bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.scss']
})
export class BarComponent implements OnInit {
  // group containers
  private gx: any; // X axis
  private gx1: any; // 2nd X axis
  private gy: any; // Y axis
  private bars: any; // Bars
  public labels: any; // labels

  // Scales and Axis
  private xAxis: Axis<AxisDomain>;
  private xScale: any;
  private xAxis1: Axis<AxisDomain>;
  private xScale1: any;
  private yAxis: Axis<AxisDomain>;
  private yScale: any;

  // Drawing containers
  private mainContainer: any;
  total: number;
  public tooltip: any;
  private svg;
  private dataSource: any;

  private margin:any= { top: 20, right: 10, bottom: 30, left: 100 };
  private width = 1060 - this.margin.left - this.margin.right;
  private height = 650 - this.margin.top - this.margin.bottom;
  private colors: any;
  @Input() data: any=[];
  @Input() row: any=[];
  @Input() col: any=[];
  noXaxis = false;
  fontsize:any = '22px';
  ir:any=0;
  ic:any=0;
  mode:any;
  tabSize = true;
  constructor(public commonService:CommonService,public changeDetector:ChangeDetectorRef) {}

  public updateData() {
    console.log('updateData');
    d3.selectAll("svg").remove(); 
    this.ngOnInit();
  }

  ngOnInit() {
    console.log("on init called");
    this.commonService.nightmode.subscribe(data => {
      d3.selectAll("svg").remove(); 
      if (this.tabSize) {
        this.assignValues(700,180,170,85,75);
      } else {
        this.assignValues(500,140,130,70,60);
      }
    });
    this.commonService.tabSize.subscribe(data => {
      this.tabSize = data;
      console.log("tab size changed "+data);
      d3.selectAll("svg").remove(); 
      if(this.tabSize){
        this.assignValues(700,180,170,85,75);
      }else{
        this.assignValues(500,140,130,70,60);
      }
    });
    this.mode = (localStorage.getItem('mode')=="true")?'lightgray':'white';

    this.assignValues(700,180,170,85,75);
  }

  private assignValues(h1,h2,h3,h4,h5){
    if(this.row.length == 1 && this.col.length == 1){
      this.changeDetector.detectChanges();

      //rescale values
      this.margin = { top: 20, right: 10, bottom: 20, left: 100 };
      this.width = 1060 - this.margin.left - this.margin.right;
      this.height = h1 - this.margin.top - this.margin.bottom;
      this.fontsize = '22px';
    
      this.ir = 0;
      this.ic = 0;
      this.row1col1();
    }
    if(this.row.length == 2 && this.col.length == 1){
      this.changeDetector.detectChanges();

      //rescale values/
      this.margin = { top: 6, right: 10, bottom: 15, left: 60 };
      this.width = 530 - this.margin.left - this.margin.right;
      this.height = h2 - this.margin.top - this.margin.bottom;
      this.fontsize = '8px';

      this.ir = 0;
      this.ic = 0;
      this.row1col1();

      //rescale values
      this.margin = { top: 6, right: 10, bottom: 0, left: 60 };
      this.width = 530 - this.margin.left - this.margin.right;
      this.height = h3 - this.margin.top - this.margin.bottom;

      this.ir = 1;
      this.ic = 0;
      this.row2col1();
    }
    if(this.row.length == 3 && this.col.length == 1){
      this.changeDetector.detectChanges();

      //rescale values
      this.margin = { top: 6, right: 10, bottom: 15, left: 60 };
      this.width = 350 - this.margin.left - this.margin.right;
      this.height = h4 - this.margin.top - this.margin.bottom;
      this.fontsize = '5px';

      this.ir = 0;
      this.ic = 0;
      this.row1col1();

      //rescale values
      this.margin = { top: 6, right: 10, bottom: 0, left: 60 };
      this.width = 350 - this.margin.left - this.margin.right;
      this.height = h5 - this.margin.top - this.margin.bottom;

      this.ir = 1;
      this.ic = 0;
      this.row2col1();

      this.ir = 2;
      this.ic = 0;
      this.row3col1();
    }
    if(this.row.length == 1 && this.col.length == 2){
      this.changeDetector.detectChanges();

      //rescale values
      this.margin = { top: 50, right: 10, bottom: 50, left: 100 };
      this.width = 1060 - this.margin.left - this.margin.right;
      this.height = h1 - this.margin.top - this.margin.bottom;
      this.fontsize = '22px';
    
      this.ir = 0;
      this.ic = 1;
      this.row1col2();
    }
  }


  get barWidth(): number {
    return this.width;
  }
  get barHeight(): number {
    return this.height - this.margin.top - this.margin.bottom;
  }

  private initSvg() {
    let ht1 = 40;
    let ht2 = 30;
    if(this.row.length == 1){
      ht1 = 80;
      ht2 = 10;
    }else if(this.row.length == 2 && this.ir == 0){
      ht1 = 40;
      ht2 = 5;
    }else if(this.row.length == 3 && this.ir == 0){
      ht1 = 40;
      ht2 = 0;
    }

    this.mainContainer = this.svg.append('g')
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);

    this.gy = this.mainContainer.append('g')
      .attr('class', 'axis axis--y');

    this.mainContainer.append("text")
			.attr("transform", "rotate(-90)")
			.attr("y", 0 - ht1) //0 -40
			.attr("x", 0 - (this.height / 2))
      .classed('axis-title', true)
			.style("text-anchor", "middle")
			.style('stroke', 'darkgray')
      .style('stroke-width', '0.3')
      .style('font-size', this.fontsize)
			.text(this.row[this.ir]);

    this.gx = this.mainContainer.append('g')
      .attr('class', 'axis axis--x');

    this.mainContainer.append("text")
			.attr("y", this.height + ht2) //this.height + 30
			.attr("x", (this.width/2))
			.classed('axis-title', true)
			.style("text-anchor", "middle")
			.style('stroke', 'darkgray')
      .style('stroke-width', '0.3')
      .style('font-size', this.fontsize)
			.text(this.col[this.ic]);

    if(this.row.length == 1 && this.col.length == 2){
      this.gx1 = this.mainContainer.append('g')
        .attr('class', 'axis axis--x');
    }

    this.draw();
    this.resize();
    // window.addEventListener('resize', this.resize.bind(this));
  }

  private drawBars() {
    this.bars = this.mainContainer.selectAll('.bar')
      .remove()
      .exit()
      .data(this.dataSource)
      .enter().append('rect')
      .attr('class', d => d[this.row[this.ir]] > 0 ? 'bar bar--positive' : 'bar bar--negative');

    this.bars
      .attr('x', d => this.xScale(d[this.col[this.ic]]))
      .attr('y', this.yScale(0))
      .attr('width', this.xScale.bandwidth())
      .transition()
      //.ease(d3.easeBounce) //for bounce effect
      .duration(1000)
      //.delay((d, i) => i * 80) //for bounce delay
      .attr('y', d => this.yScale(d[this.row[this.ir]]))
      .attr('height', d => Math.abs(this.yScale(d[this.row[this.ir]]) - this.yScale(0)))
      .style('fill', (d, i) => 'steelblue');
      //.style('fill', (d, i) => this.colors(i)); //for different colours


    this.bars
      .on('mousemove', function (s) {
        const data = s.currentTarget.__data__;
        this.tooltip
          .style('top', (s.layerY + 15) + 'px')
          .style('left', (s.layerX) + 'px')
          .style('display', 'block')
          .style('background', 'white')
          .style('color', 'black')
          .style('opacity', 1)
          .style('height', '40px')
          .html('Unit: ' + data[this.col[this.ic]] + '<br>' + 'Consume: ' + data[this.row[this.ir]] + '<br>');
      }.bind(this))
      .on('mouseover', function (data, i, arr) {
        const interval = 3;
      }.bind(this))
      .on('mouseout', function (data, i, arr) {
        this.tooltip.style('display', 'none');
        this.tooltip.style('opacity', 0);
      }.bind(this));
  }

  private drawAxis(color) {
    color = (localStorage.getItem('mode')=="true")?'white':'darkgray';
    this.gx.attr('transform', `translate(0, ${this.yScale(0)})`).call(this.xAxis);
    this.gx.selectAll("path")
      .style("stroke", color);

    this.gx.selectAll("line")
      .style("stroke", 'white'); //made white for 2nd and 3rd x axis lines invisible

    if(!this.noXaxis){
      this.gx.selectAll("line")
        .style("stroke", color);

      this.gx.selectAll("text")
        .style("stroke", color)
        .style("stroke-width", '0.3')
        .style('font-size', this.fontsize);
    }

    this.gy.attr('transform', `translate(0, 0)`).call(this.yAxis);
    this.gy.selectAll("line")
      .style("stroke", color);
  
    this.gy.selectAll("path")
      .style("stroke", color);
  
    this.gy.selectAll("text")
      .style("stroke", color)
      .style("stroke-width", '0.3')
      .style('transform', `translate(0, -1px)`)
      .style('font-size', this.fontsize)

    if(this.row.length == 1 && this.col.length == 2){
      this.gx1.attr('transform',`translate(20,20)`).call(this.xAxis1);
      this.gx1.selectAll("path")
        .style("stroke", color);

      this.gx1.selectAll("line")
        .style("stroke", color);

      this.gx1.selectAll("text")
        .style("stroke", color)
        .style("stroke-width", '0.3')
        .style('font-size', this.fontsize);
    }
  }

  private setAxisScales() {
    this.xScale = d3.scaleBand();
    this.yScale = d3.scaleLinear();

    this.xScale
      .rangeRound([0, this.barWidth]).padding(.1)
      .domain(this.dataSource.map(d => d[this.col[this.ic]]));
    this.yScale
      .range([this.barHeight, 0])
      .domain([0, Math.max(...this.dataSource.map(x => x[this.row[this.ir]]))]);
    this.xAxis = d3.axisBottom(this.xScale);
    this.yAxis = d3.axisLeft(this.yScale);
    
    if(this.row.length == 1 && this.col.length == 2){
      this.xScale1 = d3.scaleBand();
      this.xScale1
        .rangeRound([0, this.barWidth]).padding(.1)
        .domain(this.dataSource.map(d => d[this.col[this.ic-1]]));
      this.xAxis1 = d3.axisTop(this.xScale);
    }
  }

  private draw() {
    this.setAxisScales();
    this.drawAxis(this.mode);
    this.drawBars();
    this.drawLabels();
    this.drawBarTitle();
  }

  resize() {
    this.setAxisScales();
  }

  private drawLabels() {
    if(!this.noXaxis){
      this.labels = this.mainContainer.selectAll('.label')
      .remove()
      .exit()
      .data(this.dataSource)
      .enter().append('text')
      .attr('class', 'label')
      .attr('x', d => this.xScale(d[this.col[this.ic]]) + (this.xScale.bandwidth() / 2))
      .attr('y', d => this.yScale(d[this.row[this.ir]]) + (d[this.row[this.ir]] < 0 ? 15 : -5))
      .transition()
      .duration(1500)
      .delay((d, i) => 1000 + i * 100)
      //.text(d => Math.floor(d.Rating)); //adds value to top of bar
    }else{
      this.labels = this.mainContainer.selectAll('.label')
      .remove()
      .exit()
      .data(this.dataSource)
      .enter().append('text')
      .attr('class', 'label')
      .attr('y', d => this.yScale(d[this.row[this.ir]]) + (d[this.row[this.ir]] < 0 ? 15 : -5))
      .transition()
      .duration(1500)
      .delay((d, i) => 1000 + i * 100)
    }
    
  }

  public row1col1(){
    this.noXaxis = false; //to remove x axis
    
    console.log(this.data.length);
    this.dataSource = [];
    for(let i=0;i<20;i++){//for large data sets
      this.dataSource.push(this.data[i])
    }
    //this.total = this.dataSource.reduce((sum, it) => sum += Math.abs(it.Rating), 0);
    this.svg = d3.select('#bar1').append('svg').attr(
      "viewBox",
      `0 0 ${this.width + this.margin.left + this.margin.right} ${this.height +
        this.margin.top +
        this.margin.bottom}`
    )
    // bar colors
    this.colors = d3.scaleLinear().domain([0, this.data.length]).range(<any[]>['red', 'blue']);
    this.initSvg();

    this.tooltip = d3.select('#bar1')
      .append('div')
      .attr('class', 'tooltip')
      .style('display', 'none')
      .style('opacity', 0);
  }

  public row2col1(){
    this.noXaxis = true; //to remove x-axis

    this.dataSource = [];
    for(let i=0;i<20;i++){//for large data sets
      this.dataSource.push(this.data[i])
    }
    //this.total = this.dataSource.reduce((sum, it) => sum += Math.abs(it.Rating), 0);
    this.svg = d3.select('#bar2').append('svg').attr(
      "viewBox",
      `0 0 ${this.width + this.margin.left + this.margin.right} ${this.height +
        this.margin.top +
        this.margin.bottom}`
    )
    // bar colors
    this.colors = d3.scaleLinear().domain([0, this.data.length]).range(<any[]>['red', 'blue']);
    this.initSvg();

    this.tooltip = d3.select('#bar2')
      .append('div')
      .attr('class', 'tooltip')
      .style('display', 'none')
      .style('opacity', 0);
  }

  public row3col1(){
    this.noXaxis = true; //to remove x-axis

    this.dataSource = [];
    for(let i=0;i<20;i++){//for large data sets.
      this.dataSource.push(this.data[i])
    }
    //this.total = this.dataSource.reduce((sum, it) => sum += Math.abs(it.Rating), 0);
    this.svg = d3.select('#bar3').append('svg').attr(
      "viewBox",
      `0 0 ${this.width + this.margin.left + this.margin.right} ${this.height +
        this.margin.top +
        this.margin.bottom}`
    )
    // bar colors
    this.colors = d3.scaleLinear().domain([0, this.data.length]).range(<any[]>['red', 'blue']);
    this.initSvg();

    this.tooltip = d3.select('#bar3')
      .append('div')
      .attr('class', 'tooltip')
      .style('display', 'none')
      .style('opacity', 0);
  }

  public row1col2(){
    //this.noXaxis = true; //to remove x-axis
    console.log(this.data.length);
    this.dataSource = [];
    for(let i=0;i<this.data.length;i++){//for large data sets.
      this.dataSource.push(this.data[i])
    }
    //this.total = this.dataSource.reduce((sum, it) => sum += Math.abs(it.Rating), 0);
    this.svg = d3.select('#bar4').append('svg').attr(
      "viewBox",
      `0 0 ${this.width + this.margin.left + this.margin.right} ${this.height +
        this.margin.top +
        this.margin.bottom}`
    )
    // bar colors
    this.colors = d3.scaleLinear().domain([0, this.data.length]).range(<any[]>['red', 'blue']);
    this.initSvg();

    this.tooltip = d3.select('#bar4')
      .append('div')
      .attr('class', 'tooltip')
      .style('display', 'none')
      .style('opacity', 0);
  }

  private drawBarTitle(){
	  this.mainContainer.append('g')
	    .attr("transform", "translate(" + 10 + "," + (this.height+0) + ")")
		  .selectAll('g')
		  .data(this.dataSource)
		  .enter()
		  .append('g')
			  
      .attr('transform', (d:any, i:any)=>{
        return "translate(" + (i * this.width/this.dataSource.length) + "," + 0 + ")"
      })
        
      .append('text')
        .text((d:any, i:any)=>{ 
          return d[this.col[1]];
        })
        .classed('trend-type', true)
        .style("text-anchor", "middle")
        .attr("transform", "rotate(-80)");
	}
}
