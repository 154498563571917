import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class ConfirmService {
    private subject = new Subject<any>();  
    constructor() { }  
    confirmThis(type: string,message: string, siFn: () => void, noFn: () => void) {  
        let self = this;  
        self.setConfirmation(type,message, siFn, noFn);  
    }  
    setConfirmation(type: string,message: string, siFn: () => void, noFn: () => void) {  
        let self = this;  
        this.subject.next({  
            type: type,  
            text: message,  
            siFn: function () {  
                self.subject.next(); //this will close the modal  
                siFn();
            },  
            noFn: function () {  
                self.subject.next();  
                noFn();
            }  
        });  
  
    }  
  
    getMessage(): Observable<any> {  
        return this.subject.asObservable();  
    } 
}